.admin-container, .admin-container > .admin-container-navbar {
    margin-top: 3%;
    min-height: 95vh;
    scroll-snap-align: none !important;
}

.admin-container-navbar {
    display: flex;
}

.vertical-nav {
    background-color: #f1f1f1;
    text-align: left;
    padding: 3%;
    width: 15%;
}

.vertical-nav > .nav {
    position: absolute;
    width: 13%;
}

.vertical-nav .nav-link {
    color: black !important;
    margin-bottom: 12%;
}

.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
    color: #DAA676 !important;
    background-color: transparent;
}

.nav-detail-container {
    width: 95%;
    padding: 3%;
}

.admin-table > thead > tr > th:last-child, .admin-table > tbody > tr > td:last-child {
    text-align: right;
    width: 5%;
}

.admin-table > thead > tr > th:first-child {
    text-align: left;
}

.admin-table tr {
    border: 1px solid #707070;
}

.admin-table th{
    background-color: black;
    color: #f1f1f1;
}

.admin-table th, .admin-table td {
    padding: 1%;
}

.admin-table > thead > tr > th:first-child > h1 {
    margin-bottom: 0;
}

.table-button {
    width: 100%;
    padding: 3%;
}

.admin-form {
    width: 95%;
    margin: 3%;
}

.form-heading {
    margin-bottom: 10%;
}

.form-button {
    width: 100%;
    margin-top: 3%;
    margin-right: 25%;
    padding-top: 1%;
    padding-bottom: 1%;
}

.gold-border-button.form-button {
    color: #DAA676;
}

.gold-border-button.form-button:hover {
    color: white;
}

.action-column > span {
    cursor: pointer;
}

.edit-button {
    margin-right: 10%;
}

.image-column {
    width: 20%;
}

.image-column > img {
    width: 50%;
}

.action-container {
    display: flex;
    align-items: center;
    margin-bottom: 1%;
}

.action-container > .action-button-container {
    margin-left: auto;
    width: 15%;
}

.action-container > .action-button-container > button {
    width: 45%;
}

.action-container > .action-button-container > button:first-child {
    color: #DAA676;
    margin-right: 5%;
    padding: 4%;
}

.action-container > .action-button-container > button:first-child:hover {
    color: white;
}

.action-container > .action-button-container > button:last-child {
    margin-left: 5%;
    padding: 4%;
}

.admin-heading {
    margin-top:3%;
    margin-bottom: 5%;
}

.admin-form img {
    width: 100%;
    max-width: 100%;
}

.admin-form .custom-file img {
    width: auto;
}

.text-editor {
    border: 2px solid;
    padding: 2%;
    border-radius: 12px;
}

.codex-editor__redactor {
    padding: 0 !important;
}






