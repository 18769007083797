footer {
    padding: 2% 0 2%;
    color: white;
}

footer a {
    width: fit-content;
    margin-top: 5%;
}


footer h1 {
    color: #DAA676;
    margin-bottom: 10%;
    font-size: 20px !important; 
}

footer .contact-details-container {
    color: white;
    margin-top: 10%;
}

footer .social-media-link-container {
    text-align: right;
}

footer .social-media-link-container > .nav-link {
    margin-top: 0;
}

.logo-footer {
    height: 6.5vmin;
}

.yellawt {
    color: #FFF;
    font-style: normal;
    font-weight: 400;
    line-height: 15px;
    text-decoration: none;
}

.yellawt:hover {
    color: #DAA676;
    text-decoration: none;
}

.cpy-SP {
    font-size: 14px;
}

.footer-rights {
    margin: -20px 0px -20px 0px;
}

.footer .black-background .container .padding-remover{
    padding: 0 !important;
}

@media (max-width: 1024px) and (min-height: 1366px) {
    footer > .container > .d-flex > .p-2:first-child {
        width: 20%;
    }

    footer > .container > .d-flex > .p-2:first-child > img {
        width: 50%;
    }
}

@media (max-width: 768px) {
    footer {
        text-align: center;
        padding: 52px 24px !important;
    }

    footer > .container .d-flex {
        display: block !important;
        text-align: left;
    }

    footer > .container .d-flex > .p-2:first-child {
        margin-bottom: 50px;
    }

    footer > .container .d-flex .nav-link {
        margin: 5% auto;
        margin-left: auto;
        font-size: 14px !important;
    }

    footer .social-media-link-container {
        text-align: center;
    }

    footer .social-media-link-container > .nav-link {
        margin: 5% !important;
    }

    .padding-remover{
        padding: 0 !important;
    }
    .padding-remover p{
        margin-top: 30px !important;
        color: #FFF;
        font-family: 'Space Grotesk';
        font-size: 15px !important;
        font-style: normal;
        font-weight: 300;
        line-height: 27px !important; /* 180% */
        width: 100% !important;
        text-align: left !important;
        padding: 0 !important;
    }

    .flx-SP-dev{
        display: flex;
        align-items: baseline;
        flex-direction: row;
        justify-content: flex-start;
        column-gap: 30px;
        margin-bottom: 15px;
    }
    .Flx-Devider .nav-link{
        text-align: left !important;
        margin: unset !important;
        color: #FFF;
        font-family: 'Space Grotesk';
        font-size: 12px !important;
        font-style: normal;
        font-weight: 300 !important;
        line-height: 15px !important; /* 125% */
        text-transform: capitalize;
        margin-bottom: 16px !important;
    }
    .ftr-head-sp{
        display: block;
        margin: unset !important;
        color: #DAA676;
        font-family: Integral CF !important;
        font-size: 15px !important;
        font-style: normal;
        font-weight: 700 !important;
        line-height: 15px !important; /* 100% */
        text-transform: uppercase !important;
        min-width: 108px;
    }
    .cpy-SP{
        padding-top: 35px !important;
        color: #FFF;
        font-family: 'Space Grotesk';
        font-size: 11.25px !important;
        font-style: normal;
        font-weight: 300;
        line-height: 15px; /* 133.333% */
        margin-bottom: 17px !important;
    }

    footer .social-media-link-container {
        text-align: left !important;
    }
    footer .social-media-link-container .nav-link{
        margin-left: 0 !important;
        margin-right: 30px !important;
    }
}

@media (max-width: 768px) {
    footer > .container .d-flex > .p-2:first-child > img {
        width: unset;
    }
}
@media (min-width:1024px){
    .spc-1-footer{
        font-size: 16px !important;
    }
    .ftr-head-sp{
        font-size: 16px !important;
    }
    .spc-2-nav-lnk a{
        font-size: 16px !important;
    }
    .logo-footer {
        height:fit-content;
        width: 44% !important;
    }
    .spc-ftr-box {
        margin-right: 6% !important;
        width: 15%;
    }
        
}