@font-face {
    font-family: Integral CF;
    src: url('./assets/fonts/Integral CF/IntegralCF-Regular.ttf');
}

@font-face {
    font-family: Integral CF Demi Bold;
    src: url('./assets/fonts/Integral CF/IntegralCF-DemiBold.ttf');
}

@font-face {
    font-family: Photoshoot;
    src: url('./assets/fonts/Photoshoot/Photoshoot-Regular.otf');
}

#root {
    font-family: 'Space Grotesk';
    font-size: 18px;
    /*scroll-snap-type: y mandatory;*/
    /*scroll-behavior: smooth;*/
    max-height: 100vh;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
}

#body > div:first-child > div {
    scroll-snap-align: start;
    scroll-snap-stop: always;
}

.container {
    max-width: 95%;
}

.social-link:hover {
    color: #DAA676 !important;
}

.nav-link {
    color: white;
    padding: 0;
}

.nav-link:hover {
    text-decoration: none;
    cursor: pointer;
    color: #DAA676 !important;
}

.nav-link:focus {
    color: #DAA676;
}

.header-paragraph {
    font-size: 24px;
    width: 40%;
    line-height: 40px;
    text-align: center;
    margin: auto;
}

.heading {
    font-family: "Integral CF Demi Bold";
    font-size: 28px;
}

.header-heading {
    font-size: 64px;
    margin-bottom: 3%;
}

.page-heading {
    font-size: 32px;
    margin-bottom: 2%;
}

.social-media-link-container > .nav-link {
    padding: 0;
    display: inline-flex;
    margin-right: 5%;
}

.social-media-link-container > .nav-link:last-child {
    margin-right: 0;
}

hr {
    border-top: 2px solid #FFFFFF;
    margin: 3% 0;
}

.grey-background {
    background-color: #F6F6F6;
}

.light-grey-background {
    background-color: #FCFCFC;
}

.black-background {
    background-color: #111111;
}

.carousel-root, .carousel, .carousel .slider-wrapper, .carousel .slider-wrapper.axis-horizontal .slider {
    height: 100%;
}

.carousel .slider-wrapper {
    margin-bottom: 5% !important;
}

.carousel .control-dots .dot {
    transform: skew(-20deg);
    border-radius: 0 !important;
    box-shadow: none !important;
    background: transparent !important;
    border: 1px solid white !important;
    margin: 0 0.2% !important;
    height: 17px !important;
    width: 23px !important;
    opacity: 1 !important;
}

.carousel .control-dots .dot.selected, .carousel .control-dots .dot:hover {
    background: white !important;
    width: 42px !important;
}

.btn {
    border-radius: 0;
    font-family: "Integral CF";
    font-size: 14px;
    border: 2px solid;
    padding: 0;
    width: 13%;
}

.btn > .nav-link {
    font-family: inherit;
    font-size: inherit;
    padding: 5%;
}


.gold-button, .white-button:hover, .gold-border-button:hover {
    background-color: #292929;
    color: white;
    border-color: #DAA676;
}

.gold-button:hover, .white-button {
    background-color: transparent;
    color: #292929;
    border-color: white;
}

.gold-button:hover {
    border-color: #DAA676;
}

.gold-border-button, .white-background-button:hover {
    background-color: #FFDDA9;
    color: white;
    border-color: #FFDDA9;
}

.gold-button > .nav-link,
.white-button:hover > .nav-link,
.gold-border-button:hover > .nav-link,
.location-button-container > div > .btn > .nav-link:hover {
    color: white !important;
}

.gold-button:hover > .nav-link, .white-button > .nav-link, .location-button-container > div > .btn > .nav-link {
    color: #292929 !important;
}

.white-background-button {
    background-color: white;
    color: #DAA676;
    border-color: white;
}

/*.white-background-button:hover {*/
/*    background-color: transparent;*/
/*    color: #DAA676;*/
/*    border-color: #DAA676;*/
/*}*/

.white-background-button > .nav-link {
    color: inherit;
}

.location-button-container {
    padding: 5% 0;
    margin-left: 5%;
    scroll-snap-align: none !important;
}

.location-button-container > div {
    display: flex;
    align-items: center;
    column-gap: 50%;
}
@media (max-width:678px){

    .location-button-container {
        padding: 23px 24px;
        margin-left: unset;
        scroll-snap-align: none !important;
    }

    .location-button-container > div {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        justify-self: left;
        row-gap: 24px;
    }
}


.location-button-container > div > h2 {
    font-family: 'Integral CF';
    margin: auto;
    font-weight: 500;
}

.location-button-container > div > p {
    width: 40%;
    margin-bottom: 2%;
}

.location-button-container > div > button {
    margin: auto;
}

textarea {
    min-height: 150px;
}

.custom-file-label {
    background-color: #EEEEEE;
    border: 2px dashed;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 3px;
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 0;
    cursor: pointer;
}

.custom-file, .custom-file-label, .custom-file-input {
    height: 25vh;
    margin-left: 0;
    text-align: center;
}

.custom-file-input {
    z-index: 1 !important;
}

.custom-file > img {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    border-radius: 3px;
    z-index: 1;
}

.custom-file > img:hover ~ .custom-file-label, .custom-file-input:hover ~ .custom-file-label {
    z-index: 2;
    opacity: 0.5;
}

.small-input, .small-input > .custom-file-label, .small-input > .custom-file-input {
    height: 8vh;
    margin: 3% 0;
}

.small-input > .custom-file-label > img {
    width: 20%;
}

.small-input > .custom-file-label > .image-uploader-label {
    font-size: 12px;
}

.custom-file-label::after {
    display: none;
}

.custom-file-input {
    padding: 0 !important;
    cursor: pointer;
    z-index: 0;
}

/* Editor */
.ce-block__content {
    max-width: 100%;
}

.cdx-simple-image__caption, .image-tool__caption {
    display: none;
}

.fade-animation {
    animation: fadeAnimation 1s ease-out;
}

@keyframes fadeAnimation {
    0% {
        opacity: 0;
        transform: translateY(-10rem) rotate(0deg);
    }
    100% {
        opacity: 1;
        transform: scale(1) rotate(0deg);
    }
}

@media (max-width: 1366px) {
    #root {
        scroll-snap-type: none;
    }

    .fade-animation {
        animation: none;
    }
}

@media (max-width: 1024px) and (min-height: 1366px) {
    .container {
        max-width: 90%;
    }

    .btn {
        width: 20%;
    }

    .carousel .slider-wrapper {
        margin-bottom: 10% !important;
    }

    .social-media-link-container > .nav-link {
        margin-right: 10%;
    }
}

@media (max-width: 768px) {
    .container {
        max-width: 100%;
    }

    .carousel .slider-wrapper {
        margin-bottom: 10% !important;
    }

    .btn {
        width: 25%;
    }

    .location-button-container > div > .btn {
        margin-left: 0;
    }

    .header-heading {
        margin-bottom: 5%;
    }

    .header-paragraph {
        width: 70% !important;
        text-align: center;
        margin-left: 14%;
        line-height: 32px;
        padding: 0;
        font-size: 18px;

    }
}

@media (max-width: 600px) {
    .header-heading {
        font-size: 24px;
    }

    .carousel .control-dots .dot {
        margin: 0 1% !important;
    }

    .carousel .slider-wrapper {
        margin-bottom: 20% !important;
    }

    .btn {
        width: 50%;
    }

    .location-button-container > div > .btn {
        width: 60%;
        margin: 0 auto !important;
        margin-left: 0 !important;
        margin-top: 30px !important;
    }

    .location-button-container {
        font-size: 24px;
    }

    .heading {
        font-size: 24px;
    }

    .page-heading {
        color: #000;
        font-family: Integral CF;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 134.2%; /* 32.208px */
        letter-spacing: 1.44px;
    }

    p {
        font-size: 15px;
        width: 100%;
        text-align: center;
    }

    .header-heading {
        margin-bottom: 24px;
        text-align: center;
        width: 70%;
        margin-left: 0;
    }
    #home-main-heading{
        font-size: 36px!important;
        color: #FFF;
        text-align: center;
        font-family: Integral CF;
        font-style: normal;
        font-weight: 700;
        letter-spacing: 1.92px;
        font-family: Integral CF;
        width: 88%;
        margin: 0 auto;
        padding-bottom: 14px;
        line-height: 44px;
    } 
    .btn > .nav-link {
        color: #292929;
        text-align: center;
        font-family: Integral CF;
        font-size: 17px;
        font-style: normal;
        font-weight: 400;
        line-height: 17px; /* 100% */      
        
    }
    .term-and-condition-container p{
        text-align: left !important;
    }

    .mobile-partner-container .carousel .slider-wrapper.axis-horizontal .slider {
        -ms-box-orient: horizontal;
        display: -moz-flex;
        display: flex;
        align-items: center !important;
    }
}

@media (max-width: 400px) {
    .location-button-container > div {
        display: block;
        margin-top: 0%;
    }

    .location-button-container > div > button {
        margin: 10% auto !important;
    }
}

.ytp-show-cards-title{
    display: none !important;
}
.custom-service-portal .col-md-12{
    padding-left: 0 !important;
    padding-right: 0 !important;
}

@media(min-width:1200px){
    .nme-sp-p{
        font-size: 28px;
    }
    .container-boxed{
            max-width: 90rem;
    }
    .container-boxed-special{
        max-width: 92.8rem;
        margin:  0 auto;
    }
}
@media(min-width:1500px){
    .get-in-touch-details {
        top: 15vw;
        padding-left: 11.59%;
        width: 58%;
        position: absolute;
        color: #ffff;
    }
}