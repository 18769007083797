.home-header {
    height: 100vh;
}

.home-header p {
    margin-bottom: 3%;
    width:32%;
}

.home-heading {
    text-align: center;
    font-family: 'Integral CF';
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 2px;
    display: block;
}

#home-paragraph-description{
    margin-top: -1%!important;
}
.carousel-detail-container {
    height: 100%;
    color: white;
    position: relative;
}

.carousel-detail-container::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
}

.home-header .carousel-detail-container .btn {
    width: 13%;
}

.home-header .carousel-detail-container.first::before {
    background: url("../images/hdrimg.webp") no-repeat center;
    background-size: cover;
}

.carousel-detail-container > div {
    position: relative;
    padding: 17% 0;
}

.home-header .carousel .control-dots {
    bottom: 10%;
}

.testimonial-container {
    padding: 12% 0 7%;
    padding-left: 39px !important;
    padding-right: 33px !important;
}

.testimonial-container > .carousel-root {
    width: 40%; 
}

.testimonial-detail-container {
    width: 80%;
    margin: auto auto 5%;
    font-size: 20px;
}

.testimonial-person-container {
    font-family: "Integral CF";
    font-size: 16px;
    color: #8D8D8D;
    margin-bottom: 2%;
}

.testimonial-person-container > h3 {
    font-size: 24px;
    font-weight: bold;
    color: black;
}

.slick-dots {
    gap: 28px !important;
    display: flex !important;
    justify-content: center !important;
    bottom: -30px !important;
}

.slick-dots li button:before {
    background: #00000084 !important;
    color: transparent !important;
    border: 1px solid #00000084 !important;
    transform: skew(-20deg);
    margin: 50% 5%!important;
    height: 17px!important;
}

.slick-dots li.slick-active button:before{
    width: 35px !important;
    background: #212529 !important;
    color: transparent !important;
    transform: skew(-20deg);
    margin: 50% -35%!important;
    height: 17px!important;
    margin-right: 2px;
}

.location-button-container {
    background-color: #D9D9D94F;
    margin-left: 0;
    max-width: 100%;
}

.loimg:hover {
    color: #FFFF;
}

.hdr {
    background-color: #DAA676;
    border-color: #DAA676;
    width: auto;
}

.hdr:hover {
    border-color: #292929;
}

.hdr > .nav-link {
    color: #292929 !important;
}

.hybridcar {
    border: solid 5px;
    border-image: linear-gradient(180deg, white, #292929) 1;
    margin-top: 8%;
    max-width: 100%;
    padding-left: 5%;
    padding-right: 5%;
}

.eleccar-heading {
    width: 100%;
    font-size: 37px;
    font-family: 'Integral CF';
    line-height: 37px;
    letter-spacing: 6%;
    margin-bottom: 37px !important;
    display: block;
}

.fuelcar-container {
    max-width: 90rem;
    margin: 0 auto;
    padding-right: 0;
}

.elbt {
    width: 200px;
}

.elbt:hover {
    border: #292929;
}

.elbt > .nav-link {
    color: #292929;
}

.elcar {
    width: 697px;
}

.values-container {
    background-color: #F9F9F9;
    max-width: 100%;
    padding: 5% 3% 5% 3%;
}

.value-card-title {
    font-family: 'Space Grotesk';
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
    width: 80%;
    margin: auto;
    margin-bottom: 4%;
}

.value-card-text {
    font-family: 'Space Grotesk';
    font-size: 15px;
    line-height: 24px;
    font-weight: 400;
    color: #4E4E4E;
    width: 80%;
    margin: auto;
}

.value-card-body {
    background-color: #FFFF;
    padding: 3% 0% 3% 3%;
    box-shadow: 30px 0px 52px #0000001a;
}

.card-detail-container {
    background-color: #1A1A1A;
    width: 22%;
    min-height: 470px;
}

.blank-card {
    background-color: #1A1A1A;
}

.card-content {
    padding: 7%;
    color: #ffffff;
}

.card-para {
    font-family: 'Space Grotesk';
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
    margin-bottom: 10%;
    min-height: 3.6vw;
}

.card-heading {
    padding: 5% 0% 5%;
    font-family: 'Integral CF';
    font-size: 19px;
    font-weight: 400;
    line-height: 22px;
}

.service-button:hover {
    background-color: #ffffff;
    border: #ffffff;
}

.service-button > .nav-link:hover {
    color: #292929 !important;
}

.services-container {
    max-width: 100%;
    padding: 0px;
    justify-content: center;
    gap: 2.11rem!important;
    padding-right: 14px;
}

.blank-card-heading {
    font-family: 'Integral CF';
    font-weight: 400;
    font-size: 36px;
    line-height: 48px;
    padding: 125.5% 0 0 10%;
}

.arrow {
    background-color: transparent;
    border: none;
    padding-right: 10%;
    padding-top: 0;
}


#srv:hover {
    fill: #FFFF;
}

.testimonial {
    background-color: #FFF9F0;
    padding: 6% 10% 6% 10%;
    border-radius: 6px;
    min-height: 280px;
}

.feedbacks-text {
    font-family: 'Space Grotesk';
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
    color: #575757;
}

.feedbacks-name {
    font-family: 'Integral CF';
    font-weight: 400;
    font-size: 22px;
    line-height: 24px;
    letter-spacing: 1px;
}

.feedbacks-designation {
    font-family: 'Integral CF';
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    color: #00000084;
}

.blog-card-detail-container {
    background-color: #F6F6F6;
    width: 30%;
    min-height: 420px;
}

.blog-card-content {
    padding: 5% 7% 5% 7%;
    color: #2A2A2A;
    
}

.blog-para {
    color: #7F7F7F;
    font-family: 'Roboto';
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 140.4%;
    padding-bottom: 4%;
}

.blg-crd {
    background-color: #292929;
    color: #ffff;
    width: 150px;
}

.blg-crd .nav-link:hover {
    color: #292929 !important;
}

.video-container {
    position: relative;
}

.video-container > video {
    object-fit: cover;
}

.btn.gold-border-button {
    border: none;
}

.btn.gold-border-button:hover svg #loca {
    fill: white;
}

.btn.gold-border-button:hover svg  {
    fill: white;
}

.eleccar-paragraph {
    color: #000;
    text-align: center;
    font-family: 'Space Grotesk';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
}

.blog-ttl {
    min-height: 90px;
}

.sp-hm-fix-btn{
    padding-top: 6px;
    padding-bottom: 6px;
}

.sp-home-bnr-tx{
    font-size: 20px !important;
    line-height: 30px;
}


.sp-fix-loc-txt{
    font-weight: 400;
}

.sp-loc-btn{
    padding-bottom: 13px !important;
    padding-top: 13px !important;
}


.navbar-expand-lg .navbar-nav.sp-nv-lnk{
    justify-content: center !important;
}

.sp-sp-pc-hd{
    display: block;
}

.sp-sp-mob-hd{
    display: none;
}
.slick-dots li.slick-active button:before {
    width: 48px !important;
     background: #212529 !important;
     color: transparent !important;
     transform: unset;
     height: 4px !important;
     margin-right: 2px;
     margin: unset !important;
     margin-top: 25px !important;
     border-radius: 0px;
 }
 .slick-dots li button:before {
     height: 4px !important;
     border-radius: 0px;
     transform: unset;
    width: 48px !important;
    margin: unset !important;
    margin-top: 25px !important;

 }
@media (max-width: 1024px) and (min-height: 1366px) {
    .carousel-detail-container > div {
        position: relative;
        padding: 40% 0;
    }

}

@media (max-width: 1024px) and (min-height: 600px) {
    /*.services-container .carousel-detail-container > div {
        padding: 5% 0;
    }*/

    .carousel-detail-container > div {
        padding: 10% 0;
    }

    .home-header .carousel .control-dots {
        bottom: 5%;
    }
}

@media (max-width: 768px) {
    .carousel-detail-container > div {
        padding: 55% 0;
    }

    .home-header .carousel-detail-container .btn {
        width: 25%;
    }

    .home-header .carousel .control-dots {
        bottom: 8%;
    }
    .home-header{
        height: 76vh;
    }

    #location-container-heading-h2{
        margin-bottom: 24px;
        width: 85%;
        text-align: left;
        font-family: 'INTEGRAL CF' !important;
        font-weight: 500;
        margin: 0;
        line-height: 134.2%;
    }
    .sp-loc-btn-parent {
        display: block;
        margin: 0 auto !important;
    }
    .home-header p {
        margin-bottom: 10%;
        font-size: 18px;
        line-height: 30px;
        text-align: center;
        margin-left: 0%;
        width: 80% !important;
        margin: 0 auto;
        color: #FFF;
        text-align: center;
        font-family: 'Space Grotesk';
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 171.429% */
    }

    #home-paragraph-description{
        margin-top: -4%!important;
    }

    .location-button-container .container{
        padding: 0 !important;
    }
}

@media (max-width: 768px) and (min-height: 1024px) {
    .carousel-detail-container > div {
        padding: 40% 0;
        height: 80%;
    }


}

@media (max-width: 600px) {

    .testimonial-detail-container {
        width: 100%;
        margin: auto auto 10%;
    }

    .testimonial-person-container {
        margin: 5% 0;
    }

    .testimonial-person-container > h3 {
        font-size: 18px;
    }

    .home-header .carousel-detail-container .btn {
        width: 50%;
       
    }
}

@media (max-height: 700px) {

}

@media (max-height: 500px) {

}




/* mobile css start------------------------------------------------------------------------- */

@media (max-width: 768px) {

    .mbl {
        padding: 0px !important;
    }

    .home-header {
        height: 80vh;
    }
    
    .home-header p {
        margin-bottom: 3%;
        font-size: 18px;
    }
    
    .home-heading {
        text-align: center;
        font-family: 'Integral CF';
        font-size: 48px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: 2px;
    }
    
    #home-paragraph-description{
        margin-bottom: 24px!important;
        margin-top: unset !important;
        display: block;
        font-size: 14px !important;
        line-height: 24px !important;
    }
    .carousel-detail-container {
        height: 100%;
        color: white;
        position: relative;
        /* padding: 50% 0 0; */
    }
    
    .carousel-detail-container::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
    }
    
    .home-header .carousel-detail-container .btn {
        width:42%;
    }
    .home-header .carousel-detail-container .btn .nav-link{
        margin-top: -3px;
    }
    
    .home-header .carousel-detail-container.first::before {
        background: url("../images/hdrimg.webp") no-repeat 20%;
        background-size: cover;
    }

    
    .location-button-container>div>.btn:hover {
        border: none !important;
    }
    
    .carousel-detail-container > div {
        position: relative;
        padding: 50% 0 0;
    }
    
    .home-header .carousel .control-dots {
        bottom: 10%;
    }
    
    .testimonial-container {
        padding: 12% 0 7%;
    }
    
    .testimonial-container > .carousel-root {
        width: 40%; 
    }
    
    .testimonial-detail-container {
        width: 80%;
        margin: auto auto 5%;
        font-size: 20px;
    }
    
    .testimonial-person-container {
        font-family: "Integral CF";
        font-size: 16px;
        color: #8D8D8D;
        margin-bottom: 2%;
    }
    
    .testimonial-person-container > h3 {
        font-size: 24px;
        font-weight: bold;
        color: black;
    }
    .testimonial img{
        width: 64px !important;
        height: 64px !important;
    }
    
    .slick-dots {
        gap: 20px !important;
        display: flex !important;
        justify-content: center !important;
        bottom: -30px !important;
    }
    
    .slick-dots li button:before {
        background: #00000084 !important;
        color: transparent !important;
        border: 1px solid #00000084 !important;
        transform: skew(-20deg);
        margin: 50% 5%!important;
        height: 15px!important;
    }
    
    .slick-dots li.slick-active button:before{
        width: 50px !important;
        background: #212529 !important;
        color: transparent !important;
        transform: skew(-20deg);
        margin: 50% -50%!important;
        height: 17px!important;
    }
    
    .location-button-container {
        background-color: #D9D9D94F;
        margin-left: 0;
        max-width: 100%;
        padding-top: 62px;
        padding-bottom: 62px;
    }
    
    .loimg:hover {
        color: #FFFF;
    }
    
    .hdr {
        background-color: #DAA676;
        border-color: #DAA676;
        width: auto;
    }
    
    .hdr:hover {
        border-color: #292929;
    }
    
    .hdr > .nav-link {
        color: #292929 !important;
    }
    
    .hybridcar {
        border: solid 5px;
        border-image: linear-gradient(180deg, white, #292929) 1;
        margin-top: 0;
        max-width: 100%;
        padding-left: unset;
        padding-right: unset;
        display: flex;
        flex-direction: column;
        margin-left: unset  !important;
        margin-right: unset !important;
        row-gap: 26px;
    }
    
    .eleccar-heading {
        color: #1B1B1B;
        text-align: center !important;
        leading-trim: both;
        text-edge: cap;
        font-family: Integral CF;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 35px;
        letter-spacing: 1.44px;
        /* text-align: left !important; */
        display: block;
        margin-bottom: 16px !important;
    }
    .offer-container .eleccar-heading {
        color: #1B1B1B;
        text-align: center !important;
        leading-trim: both;
        text-edge: cap;
        font-family: Integral CF;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 35px;
        letter-spacing: 1.44px;
        /* text-align: left !important; */
        display: block;
        margin-bottom: 16px !important;
    }
    .values-container .eleccar-heading {
        color: #1B1B1B;
        text-align: center;
        leading-trim: both;
        text-edge: cap;
        font-family: Integral CF;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height:24px;
        display: block; /* 239.583% */
        letter-spacing: 1.44px;
        margin-bottom: 16px !important;
    }
    .blog-container .eleccar-heading {
        color: #1B1B1B;
        text-align: center !important;
        leading-trim: both;
        text-edge: cap;
        font-family: Integral CF;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 35px;
        letter-spacing: 1.44px;
        /* text-align: left !important; */
        display: block;
        margin-bottom: 16px !important;
    }
    .eleccar-paragraph{
        text-align: center !important;
        color: #000;
        text-align: center;
        font-family: 'Space Grotesk';
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 26.555px;
        width: 100%;
        margin: 0 auto;
        margin-bottom: 40px !important;
    }
    .values-container .eleccar-paragraph{
        color: #000;
        text-align: center;
        font-family: 'Space Grotesk';
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 150% */
    }
    .offer-container .eleccar-paragraph{
        color: #000;
        text-align: center;
        font-family: 'Space Grotesk';
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 150% */
    }
    .blog-container .eleccar-paragraph{
        color: #000;
        text-align: center;
        font-family: 'Space Grotesk';
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 150% */
    }
    .fuelcar-container {
        max-width: 100% !important;
        padding: 60px 24px !important;
        margin:unset;
    }
    
    .elbt {
        display: flex;
        padding: 7px 15.844px !important;
        justify-content: center;
        align-items: center;
        gap: 31.383px;
        width: 62%;
        margin: 0 auto;
        margin-bottom: 48px;
    }
    
    .elbt:hover {
        border: #292929;
    }
    
    .elbt > .nav-link {
        color: #292929;
        text-align: center;
        font-family: Integral CF;
        font-size: 16.094px;
        font-style: normal;
        font-weight: 400;
        line-height: 16.094px; /* 100% */
        margin-top: -4px;
    }
    
    .elcar {
        width: 100%;
        margin: 0;
    }
    
    .values-container {
        background-color: #F9F9F9;
        margin-top: 0px !important;
        padding: 60px 24px !important;
    }
    
    .value-card-title {
        text-align: left;
        width: 85%;
        margin: 0;
        margin-bottom: 16px !important;
        color: #292929;
        font-family: 'Space Grotesk';
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        text-transform: capitalize;
        width: 100%;
    }
    
    .value-card-text {
        color: #4E4E4E;
        font-family: 'Space Grotesk';
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        margin: 0;
        text-align: left;
        width: 100%;
    }
    
    .value-card-body {
        background-color: #FFFF;
        padding: 3% 0% 3% 3%;
        box-shadow: 30px 0px 52px #0000001a;
    }
    
    .card-detail-container {
        background-color: #1A1A1A;
        width: 22%;
        height: 500px;
    }
    
    .blank-card {
        background-color: #1A1A1A;
    }
    
    .card-content {
        padding: 8% 5%;
        color: #ffffff;
    }
    
    .card-para {
        font-family: 'Roboto';
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        margin-bottom: 10%;
    }
    
    .card-heading {
        padding-top: 5%;
        font-family: 'Integral CF';
        font-size: 19px;
        font-weight: 400;
        line-height: 22px;
    }
    
    .service-button:hover {
        background-color: #ffffff;
        border: #ffffff;
    }
    
    .service-button > .nav-link:hover {
        color: #292929 !important;
    }
    
    .services-container {
        max-width: 100%;
        padding: 0px;
        justify-content: center;
    }
    
    .blank-card-heading {
        font-family: 'Integral CF';
        font-weight: 400;
        font-size: 23px;
        line-height: 28px;
        padding: 30% 0 0 10%;
    }
    
    .arrow {
        background-color: transparent;
        border: none;
        padding-right: 10%;
        padding-top: 40%;
    }
    #srv:hover {
        fill: #FFFF;
    }
    
    .testimonial {
        background-color: #FFF9F0;
        padding: 10%;
        border-radius: 6px;
    }
    
    .feedbacks-text {
        font-family: 'Space Grotesk';
        font-weight: 400;
        font-size: 18px;
        line-height: 26px;
        color: #575757;
    }
    
    .feedbacks-name {
        font-family: 'Integral CF';
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 1px;
    }
    
    .feedbacks-designation {
        font-family: 'Space Grotesk';
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px; /* 100% */
        letter-spacing: 1px;
        color: #00000084;
    }
    
    .blog-card-detail-container {
        background-color: #F6F6F6;
        width: 100%;
        height: auto;
    }
    
    .blog-card-content {
        padding: 5%;
        color: #2A2A2A;
    }
    
    .blog-para {
        color: #7F7F7F;
        font-family: 'Roboto';
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 140.4%;
    }
    
    .blg-crd {
        background-color: #292929;
        color: #ffff;
        width: 150px;
    }
    
    .blg-crd .nav-link:hover {
        color: #292929 !important;
    }
    
    .video-container {
        position: relative;
    }
    
    .video-container > video {
        object-fit: cover;
    }
    
    .btn.gold-border-button:hover svg #loca {
        fill: white;
    }
    

    .value-cards{
        margin-top: 60px !important;
        display: flex !important;
        flex-direction: column !important;
        row-gap: 32px !important;
    }
    .value-card-body{
        display: flex;
        padding: 60px 24px;
        align-items: flex-start;
        gap: 26px;
        align-self: stretch;
        background: #F3F3F3;
        box-shadow: 0px 4px 52px 0px rgba(0, 0, 0, 0.10);
    }

    .offer-container {
        margin-top: 0 !important;
        padding: 80px 24px 60px!important;
    }

    .blank-card{
        width: 100% !important;
    }


    .testimonial-container{
        padding: 0px 24px 66px !important;
        margin-top: -20px !important;
    }

    .mtmargin{
        margin-top: -20px !important;

    }
    .getintouchcon{
        display: flex !important;
        flex-direction: column-reverse !important;
        background: #000;
        width: 100%;
        padding-bottom: 55px;
    }
    .get-in-touch-details{
        position: relative;
        width: 100%;
        top: unset;
        padding: unset;
        padding: 60px 24px 0px;
    }
    .getintouchcon > div > h1{
        color: #FFF;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        color: #fff;
        font-family: Integral CF;
        font-size: 28px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 109.091% */
        letter-spacing: 1px;
        margin-bottom: 26px !important;
    }
    .man-bnr {
        width: 100%;
        position: relative;
        display: none;
        /* margin-bottom: 10px; */
    }
    .getintouchcon > div > p {
        color: #FFFF;
        font-family: 'Space Grotesk';
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: 25px; /* 178.571% */
        text-align: left;
        margin-bottom: 10px;
    }
    .special-p-tag{
        text-align: left !important;
    }

    .special-h1-tag{
        margin-bottom: 20px !important;
    }
    .get-in-touch-details .btn > .nav-link {
        margin-top: -4px;
        padding: 7% 0;
    }
    .blog-container {
        margin: 0 !important;
        padding: 60px 24px !important;
    }

    .offer-container-hm .d-flex{
        display: flex !important;
        margin-top: 0 !important;
        flex-direction: column !important;
    }

    .offer-container-hm .card-detail-container{
        width: 100% !important;
        height: fit-content;

    }

    .offer-container-hm .card-heading {
        padding-top: 0%;
        color: #FFF;
        font-family: Integral CF;
        font-size: 19px;
        font-style: normal;
        font-weight: 400;
        line-height: 21.015px; /* 110.607% */
        padding-bottom: 6px;
    }

    .offer-container-hm .card-para {
        font-family: 'Space Grotesk';
        font-size: 14px;
        font-weight: 400;
        line-height: 140%;
        margin-bottom: 28px;
        text-align: left;
        width: 257px;
    }

    .offer-container-hm .service-button{
        display: flex;
        padding: 5px 18px;
        justify-content: center;
        align-items: center;
        gap: 29px;
        background: #FFF;        
    }

    .offer-container-hm .btn > .nav-link {
        color: #292929;
        text-align: center;
        font-family: Integral CF;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 15.011px; /* 107.221% */
    }

    .offer-container-hm .blank-card-heading {
        font-family: 'Integral CF';
        font-weight: 400;
        font-size: 23px;
        line-height: 28px;
        padding: 20px;
        margin-bottom: 0;
    }

    .offer-container-hm .arrow {
        background-color: transparent;
        border: none;
        padding-right: 10%;
        padding-top: 0;
    }

    .offer-container-hm .blank-card{
        display: flex !important;
        justify-content: flex-start;
        align-items: center;
        column-gap: 24px;
        display: flex !important;
        justify-content: flex-start;
        padding: 59% 0;
    }

    .offer-container-hm{
        padding-top: 50px !important;
    }


    .testimonial-container .flex-row{
        display: flex !important;
        flex-direction: column !important;
        margin-right: 0 !important;
        row-gap: 24px;
    }

    .testimonial-message-container{
        width: 100% !important;
        display: flex;
        flex-wrap: wrap;
        position: relative;
        max-width: 100%;
    }

    .testimonial-message-container p{
        display: block;
        width: 100%;
        margin-bottom: 16px;
        color: #575757;
        font-family: 'Space Grotesk';
        font-size: 16px !important;
        font-style: normal;
        font-weight: 400 !important;
        line-height: 140.4%; /* 16.848px */
        position: relative;
        padding-left: 0px;
        overflow: auto;
        display: flex;
        flex-wrap: wrap;
    }

    .special-testimo-tag{
        margin-bottom: 32px;
        display: block;
    }
    .slick-slider{
        margin-top: 20px !important;
    }


    .blog-container .d-flex{
        display: flex;
        flex-direction: column !important;

    }

    .blog-container .blog-para {
        text-align: left;
        margin-bottom: 40px;
    }

    .blog-container .card-date {
        color: #868686;
        font-family: 'Integral CF';
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        text-align: left !important;
        line-height: 20px;
        padding-left: 0 !important;
        padding-top: 12px;
    }

    .blog-container .btn > .nav-link {
        color: #fff;
        text-align: center;
        font-family: Integral CF;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        margin-top: -2px;
    }

    .blog-container .card-heading {
        padding-top: 5%;
        font-family: 'Integral CF';
        font-size: 18px;
        font-weight: 400;
        line-height: 22px;
        margin-bottom: 16px;
    }
    .location-button-container .btn{
        width: 70%;
    }
    .blog-card-content .blog-para{
        font-size: 14px;
    }
    .blog-card-content button{
        height: 50px;
    }
    .location-button-container .btn > .nav-link {
        font-family: inherit;
        font-size: inherit;
        padding: 5% 6%;
    }
    .val .values-container{
        margin-top: 0 !important;
    }
    .offr .mt-5{
        margin-top: 0 !important;
    }
    .bg-smp .mt-5{
        margin-top: 0 !important;
        padding-bottom: 60px !important;
    }

    .Sp-pc-hm-only{
        display: none !important;
    }
    .Sp-mob-hm-only{
        display: block !important;
    }

    .mob-serv-sp-only{
        display: block !important;
    }
    .pc-serv-sp-only{
        display: none !important;
    }

    .sp-visible{
        display: block !important;
    }
    .sp-visible2{
        display: none !important;
    }

    .services-container .slick-slide {
        padding: 0 1px;
    }
    .blog-card-content button{
        border: none !important;
    }
    .testimonial .ml-4{
        margin-left: 0 !important;
    }


    .sp-sp-pc-hd{
        display: none;
    }
    .sp-sp-mob-hd{
        display: block;
        width: 100%;
    }
    .slick-dots li.slick-active button:before {
       width: 40px !important;
        background: #212529 !important;
        color: transparent !important;
        transform: unset;
        height: 4px !important;
        margin-right: 2px;
        margin: unset !important;
        margin-top: 25px !important;
        border-radius: 0px;
    }
    .slick-dots li button:before {
        height: 4px !important;
        border-radius: 0px;
        transform: unset;
       width: 40px !important;
       margin: unset !important;
       margin-top: 25px !important;

    }
}

.Sp-pc-hm-only{
    display: block ;
}
.Sp-mob-hm-only{
    display: none ;
}
.sp-visible{
    display: none;
}
.sp-visible2 {
    display: block;
}


.ongoing-promo-new{
    height: 330px;
    background: url("../images/ongoing-banner.webp") no-repeat center;
    background-size: cover;
    position: relative;
    
}
.ongoing-promo-new .text-container{
    padding-top: 80px;
    padding-left: 2.59%;
    height: 330px;
    background: linear-gradient(90deg, #000000 26.93%, rgba(0, 0, 0, 0) 86.87%);

   
}
.ongoing-promo-new .gradient-right{
    position: absolute;
    height: 330px;
    right: 0;
    width: 42%;
    top: 0;
    background: linear-gradient(270deg, rgba(0, 0, 0, 0.74) 26.93%, rgba(0, 0, 0, 0) 86.87%);

}
.ongoing-promo-new .main-topic-new{
    font-family: 'Integral CF';
    font-size: 37px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.06em;
    text-align: left;
    display: block;
    color: #FFFFFF;
    padding-bottom: 26px;

}
.ongoing-promo-new .tagline-new{
    font-family: 'Space Grotesk';
    font-size: 15px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: left;
    color: #ffff;
    display: block;
    width: 40%;
    padding-bottom: 26px;
}
.ongoing-promo-new .ongoing-promo-btn{
    background: #DAA676;
    width: 189px;
    height: 50px;
    
    
}
.ongoing-promo-new .ongoing-promo-btn a{
    color: #ffff !important;
    font-family: 'Integral CF';
    font-weight: 400;
    line-height: 20px;
    text-decoration: none;
}
.ongoing-promo-new .ongoing-promo-btn:hover{
    background: #282828;
}
@media (max-width:678px){
    .ongoing-promo-new{
        height: 512px;
        background: url("../images/Promotions-banner-image-mobile.png") no-repeat center;
        background-size: cover;
        object-fit: cover;
        position: relative;
        
    }
    .ongoing-promo-new .text-container {
        padding-left: unset;
        padding: 72px 25px;
        height: 512px;
        background: linear-gradient(181deg, #00000000 32.93%, rgba(0, 0, 0, 0) 115.87%);
    }
    .ongoing-promo-new .gradient-right{
        display: none;
    
    }
    .ongoing-promo-new .main-topic-new{
        text-align: left;
        font-family: 'Integral CF';
        font-size: 30px;
        font-style: normal;
        font-weight: 400;
        line-height: 32px;
        letter-spacing: 1.44px;
        padding-bottom: 26px;
    
    }
    .ongoing-promo-new .tagline-new{
        font-family: 'Space Grotesk';
        font-size: 16px;
        font-weight: 400;
        line-height: 25px;
        letter-spacing: 0em;
        text-align: left;
        color: #ffff;
        display: block;
        width: 100%;
        text-align: left;
        padding-bottom: 26px;
    }
    .ongoing-promo-new .ongoing-promo-btn{
        background: #DAA676;
        width: 189px;
        height: 50px;
        margin: 0;
        
    }
    .ongoing-promo-new .ongoing-promo-btn a{
        color: #ffff !important;
        font-family: 'Integral CF';
        font-weight: 400;
        line-height: 20px;
        text-decoration: none;
    }
    .ongoing-promo-new .ongoing-promo-btn:hover{
        background: #282828;
    }
}
.blog-card-gray .text-star{
    min-height: 6.5vw;
}
@media(max-width:678px){
    .blog-card-gray .text-star{
        min-height:unset;
    }
}
@media(min-width:1200px){
    .container-padding-remove{
        padding: 0;
    }
    .container-padding-remove h2{
        margin: 0 !important;
    }
    .container-padding-remove button{
        margin-right: 15px !important;
    }
    .value-cards{
        padding-right: 0%;
        padding-left: 0%;
        gap: 32px !important;
    }
    .card-para {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        margin-bottom: 10%;
        
    min-height: 7.5vw;
    }
    .about-SP-flx{
        align-items: baseline !important;
    }
    .sp-gap{
        gap: 3rem !important;
        padding-right: 0;
    
    }
    .sp-gap .blog-para {
        min-height: 65px;
    }
    .form-floating>label {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        padding: 1rem .75rem;
        pointer-events: none;
        border: 1px solid transparent;
        transform-origin: 0 0;
        transition: opacity .1s ease-in-out, transform .1s ease-in-out;
        font-size: 12px;
    }
    .spc-model-popup-nme{
        font-family: Integral CF;
        font-size: 24px;
        font-weight: 400;
        line-height: 44px;
        letter-spacing: 0.06em;
        text-align: left;

    }
    .special-arr{
        margin-top: -13%;
    }
}
.spc-portal-txt{
    padding-left: 1.8px;
}

.home-header .carousel-detail-container.first {
    position: relative;
    overflow: hidden;
}

.home-header .carousel-detail-container.first::before {
    content: "";
    display: none; /* Hide the pseudo-element */
}

.background-video {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform: translate(-50%, -50%);
    z-index: -1;
    opacity: 0.8; /* Ensure the video is behind other content */
}
  .home-header .carousel-detail-container.first {
    position: relative;
    overflow: hidden;
    background: #000000c7;
}