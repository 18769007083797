.man-bnr {
    width: 100%;
    position: relative;
}

.get-in-touch-details {
    top: 15vw;
    padding-left: 2.59%;
    width: 58%;
    position: absolute;
    color: #ffff;
}

.getintouchcon > div > h1 {
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-family: 'Integral CF';
    font-size: 37px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 2.22px;
}

.getintouchcon > div > p {
    color: #FFF;
    font-family: 'Space Grotesk';
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
    width: 611px;
}

.getintouchcon {
    position: relative;
}

.service-button {
    background-color: #FFDDA9;
    border: none;
}
@media (max-width:768px){
    .getintouchcon > div > p {
        color: #FFF;
        font-family: 'Space Grotesk';
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 25px;
        width: auto;
    }
    
}
@media (min-width:1200px){
    .get-in-touch-details {
        color: #fff;
        padding-left: 12.39%;
        position: absolute;
        top: 15vw;
        width: 41%;
    }
}