.blog-page-header {
    background: url("../images/blog-img.webp") no-repeat center;
    background-size: cover;
    height: 60vh;
    color: white;
    text-align: center;
    padding-top: 10%;
}

@media (max-width:768px){
    .blog-page-header {
        background: url("../images/blogMob.webp") no-repeat center;
        background-size: cover;
        height: 57vh;
        color: white;
        text-align: center;
        padding-top: 49%;
    }
    .blg-Hdr{
        color: #FFF;
        text-align: center;
        font-family: Integral CF;
        font-size: 32px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 1.92px;       
    }

    .bg-page-only .row>* {
        flex-shrink: 0;
        width: 100%;
        max-width: 100%;
        padding-right: 0 !important;
        display: flex;
        padding-left: 0 !important;
        margin-top: 0px !important;
        flex-direction: column;
    }
    .bg-page-only .blog-container {
        margin: 0 !important;
        padding: 0px 0px !important;
    }
    .bg-page-only .blog-para {
        color: #7F7F7F;
        font-family: 'Space Grotesk';
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 166.667% */
        margin-bottom:24px;
        text-align: left;
    }

    .bg-page-only .blog-button {
        background-color: #292929;
        color: #fff !important;
        width: 50% !important;
        padding: 3% 3%!important;


    }

    .bg-page-only .btn > .nav-link {
        color: #fff;
        text-align: center;
        font-family: Integral CF;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 17px;
        padding: 6% 3%!important;
    }

    .bg-page-only .card-date{
        color: #868686;
        text-align: right;
        font-family: Integral CF;
        font-size: 12.921px;
        font-style: normal;
        font-weight: 400;
        line-height: 20.099px; /* 155.556% */
        margin-bottom: 0;
        padding-left: 0;
        padding-right: 0;
        padding-top: 0 !important;
    }

    .bg-page-only .d-flex{
        justify-content: center;
        align-items: center;
        display: flex;
        flex-direction: row !important;
        column-gap: 16px;
    }

    .bg-page-only .add-sp-top:first-child .row{
        margin-bottom: 40px !important;
    }

    .bg-page-only .row {
        --bs-gutter-x: 1.5rem;
        --bs-gutter-y: 0;
        display: flex;
        flex-wrap: wrap;
        margin-top: calc(-1 * var(--bs-gutter-y));
        margin-right: 0;
        margin-left: 0;
    }

    .bg-page-only .container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
        width: 100%;
        padding-right: 6px !important;
        padding-left: 6px !important;
        margin-right: auto;
        margin-left: auto;
    }

    .bg-page-only .d-flex {
        justify-content: flex-start;
        align-items: center;
        display: flex;
        flex-direction: row !important;
        column-gap: 16px;
    }
    .bg-page-only{
        padding-left: 23px;
        padding-right: 25px;
    
    }
    .bg-page-only .container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl
    {padding-bottom: 0px;}
}