.call-container{
    background-image: linear-gradient(to right,#DAA676,#ab815a);
    text-align: center;
    padding-top: .2%;
    padding-bottom: .2%;
}


.navbar-toggler{
    transition: ease-in;
}

.navbar-brand > img {
    width: 81%;
}

.nav-item {
    margin-left: 10%;
}

svg:hover{
    fill: #DAA676!important;
    transition: 0.3s;
}
.navbar-links > .nav-item:last-child {
    margin-left: 20%;
}

.menu-icon {
    cursor: pointer;
}

.navigation-overlay {
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    overflow-x: hidden;
    transition: 0.5s;
    max-width: 100%;
}

.navigation-overlay-content {
    position: relative;
    width: 100%;
    margin-top: 10%;
}

.navigation-overlay a {
    text-decoration: none;
    transition: 0.3s;
    color: white;
    width: fit-content;
    margin-top: 5%;
}


.navigation-overlay-content h1 {
    color: #DAA676;
    margin-bottom: 10%;
}

.navigation-overlay-content .contact-details-container {
    color: white;
    margin-bottom: 10%;
}

.navigation-overlay > .container > .navigation-close-button {
    position: absolute;
    top: 8px;
    right: 4%;
    font-size: 60px;
    font-weight: bolder;
    color: #DAA676 !important;

}

.navigation-overlay > .container > .navigation-close-button:hover {
    color: white !important;
}

.navigation-overlay-content > .social-media-link-container {
    margin-top: 10%;
}

.navbar {
    padding-left: 0;
    padding-right: 0;
}

.dropdown-menu > .nav-link {
    color: black;
}

.dropdown-menu {
    display: none;
    position: absolute;
    z-index: 1;
}

.dropdown:hover .dropdown-menu {
    display: block;
}

.fixed-top {
    border-bottom: solid 4px;
    border-image: radial-gradient(circle, #DAA676, rgba(255, 255, 0, 0), rgba(0, 128, 0, 0)) 25%;
    background-color: #191A1F !important;
}

.navlinks-container {
    justify-content: center;
}

.hd-contact {
    width: max-content !important;
}

.call-container {
    background-image: linear-gradient(to right, #DAA676, #ab815a);
    text-align: center;
    padding-top: .2%;
    padding-bottom: .2%;
    font-family: 'Space Grotesk';
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 3%;
    color: #FFFFFF !important;
    padding: auto 10px;


}
.text-bar{
    font-family: 'Space Grotesk';
    font-size: 19px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.03em;
    color:#ffff;
    text-decoration: none;
}

.text-bar:hover{
    color: #191A1F;
    text-decoration: none;

}
@media (max-width:678px){
    .call-container {
        background-image: linear-gradient(to right, #DAA676, #ab815a);
        text-align: center;
        padding-top: .2%;
        padding-bottom: .2%;
        font-family: 'Space Grotesk';
        font-size: 12px;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0.03em;
        color: #FFFFFF;
        padding: 2px 10px;
    
    
    }
    .text-bar {
        display: inline-block;
        font-family: 'Space Grotesk';
        font-size:12px;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0.03em;
        color:#ffff !important;
        text-decoration: none;
    
    }
}
@media (max-width: 1366px) {
    .navbar > .navbar-collapse > .navbar-nav:nth-child(2) {
        width: 8% !important;
    }
}

@media (max-width: 1024px) and (min-height: 1366px) {
    .navbar-links {
        width: 80%;
    }

    .navbar > .navbar-collapse > .navbar-nav:last-child {
        display: none;
    }

    .navbar > .navbar-collapse > .navbar-nav:nth-child(2) {
        width: 100% !important;
    }
}

@media (max-width: 991px) {
    #navbarSupportedContent {
        display: none;
    }
}

@media (max-width: 768px) {
    .navbar-brand {
        width: 30% !important;
    }

    .navbar-brand > img {
        width: 100%;
    }

    .navigation-overlay a {
        font-size: 20px;
    }

    .navbar-expand-lg .navbar-nav {
        flex-direction: row-reverse;
    }

    .navbar-expand-lg .navbar-nav:first-child {
        margin-right: 20% !important;
    }

    .navbar-links {
        width: 100%;
    }

    .navigation-overlay-content {
        text-align: center;
        margin-top: 20%;
    }

    .navigation-overlay-content > .d-flex {
        display: block !important;
    }

    .navigation-overlay-content > .d-flex .nav-link {
        margin: 5% auto;
    }

    .navigation-overlay-content > .social-media-link-container {
        margin-top: 0;
    }
}

@media (max-width: 600px) {
    .navbar.container {
        padding: 2% 0;
    }

    .navbar-brand {
        width: 40% !important;
    }

    .navbar-expand-lg .navbar-nav:first-child {
        margin-right: 5% !important;
    }

    .nav-item {
        margin-left: 25%;
    }

    .navbar-links > .nav-item:last-child {
        margin-left: 40%;
    }

    .navbar-links > .nav-item:last-child > span > img {
        width: 30px;
    }

    .navigation-overlay-content {
        text-align: left;
    }

    .navigation-overlay-content h1 {
        font-size: 18px;
    }

    .navigation-overlay a {
        font-size: 18px;
    }

    .navigation-overlay-content > .d-flex .nav-link {
        margin: 5% 0;
    }
    .logo-SP{
        padding-left: 24px;
    }
    .menu-icon {
        cursor: pointer;
        padding-right: 12px;
    }
}
