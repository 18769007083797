.services-header {
    background: url("../images/servicescover.webp") no-repeat center;
    background-size: cover;
    height: 60vh;
    color: white;
    text-align: center;
    padding-top: 10%;
}

.service-card-container {
    position: relative;
    height: 500px;
    margin-bottom: 25px;
}

.gradient {
    height: 500px;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.71) 91.75%);
}

.service-header-para {
    color: #FFF;
    font-family: "Space Grotesk";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-right: 10%;
    padding-left: 50px;
    margin-bottom: 42px;
}

.service-nav-btn {
    width: 200px;
    background-color: #ffe7c3;
    border: none;
    height: 40px;
    outline: none;
}
.service-nav-btn:focus{
    outline: none;
    border: none;
}
.service-nav-btn a {
    color: #292929 !important;
    text-decoration: none !important;
}

.service-nav-btn:hover a {
    color: #ffffff !important;
    text-decoration: none !important;
    outline: none;
}
.service-nav-btn:hover a:focus{
    outline: none;
    border: none;
}
.read-more-link {
    padding-left: 50px;
}

.read-more-link > button:hover {
    width: 200px;
    background-color: #292929;
    border: none;
    color: #FFFF;
}

.service-nav-link:hover {
    color: #FFFF !important;
}

.service-card-name {
    transform: translate(2%, -150%);
    -ms-transform: translate(2%, -120%);
}

.service-tag {
    color: #ffffff;
    font-family: 'Integral CF';
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 44px;
    letter-spacing: 1.92px;
    padding-left: 50px;
    padding-right: 10%;
}

.get-in-touch-container {
    margin-top: 50px;
}


.service-tiles .col-5, .col-7, .col-12{
    padding-left: 0 !important;
    padding-right: 22px;
}
.service-tiles .col-7{
    padding-right: 23px !important;
}
button:focus:not(:focus-visible) {
    outline: 0;
    outline: 0 !important;
    /* border: none; */
    box-shadow: none;
}
@media (max-width:678px){
    .services-header {
        background: url("../images/serviceMob.webp") no-repeat center;
        background-size: cover;
        height: 57vh;
        color: white;
        text-align: center;
        padding-top: 50%;
    }
    .service-card-container {
        position: relative;
        height: 400px;
        margin-bottom: 40px;
    }
    .service-card-name {
        transform: translate(24px, -120%);
        -ms-transform: translate(2%, -120%);
    }
    .service-nav-btn {
        width: 200px;
        background-color: #FFDDA9;
        border: none;
        height: 45px;
    }
    #services{
        padding-left: 24px !important;
        padding-right: 24px !important;
    }

    .service-tag {
        color: #ffffff;
        font-family: 'Integral CF';
        font-size: 24px;
        font-style: normal;
        font-weight: 300;
        line-height: 44px;
        letter-spacing: 1.92px;
        padding-left: 0 !important;
        padding-right: 10%;
        padding-bottom: 12px;
    }

    .service-header-para {
        color: #FFF;
        font-family: "Space Grotesk";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        margin-right: 10%;
        padding-left: 0 !important;
    }
    .read-more-link {
        padding-left: 0;
    }
    .service-nav-btn {
        width: 200px;
        background-color: #FFDDA9;
        border: none !important;
        height: 50px;
        margin: 0;
    }
    .service-tiles .col-5, .col-7, .col-12 {
        padding-left: 11px !important;
        padding-right: 13px !important;
        /* padding-left: unset !important; */
    }
    .service-tiles .col-7 {
        padding-right: 13px !important;
    }
    .gradient {
        height: 400px;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.71) 91.75%);
    }
    .service-card-container img{
        height: 400px !important;
    }
}