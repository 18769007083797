.contact-us-header {
    background: url("../images/contact-us-header.webp") no-repeat center;
    background-size: cover;
    height: 60vh;
    color: white;
    text-align: center;
    padding-top: 13%;
}

#contact-us-main-topic {
    font-family: 'Integral CF';
    font-size: 48px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 2px;
}

.contact-us-number-container >.col {
    gap: 10px;
}

.contact-us-number-container > .col > h3 {
    color: #ffffff;
    color: #ffffff;
    font-size: 18px;
}

.contact-us-number-container > div > .contact-details-container > p {
    margin: 0;
}

.contact-details-container > p > a {
    text-decoration: none;
    color: #8D8D8D;
}

.contact-details-container > p > a:hover {
    text-decoration: none;
    color: #ffffff;
}

.contact-us-form-container {
    color: #8D8D8D;
    padding: 5% 10% 5% 10%;
    background-color: #F1F1F1;
    justify-content: center;
}

.contact-us-form-container > .p-2 {
    padding: 0 !important;
    width: 50%;
}

.contact-us-form-container > .p-2 > h2 {
    color: #000;
    font-family: 'Space Grotesk';
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
}

.contact-us-form-container > .p-2 > img {
    width: 100%;
    height: 100%;
}

.contact-us-form-container > .p-2:first-child {
    margin-right: 5%;
    padding: 3% !important;
    text-align: center;
}

.contact-us-form-container > .p-2:last-child {
    margin-left: 5%;
}

.contact-us-form-container form {
    margin-top: 5%;
}

.contact-us-form-container button {
    width: 100%;
    padding: 3%;
    background-color: #FFDDA9;
    border: none;
    border-radius: 3px;
}

.contact-us-form-container button:hover {
    background-color: #292929;
    color: #FFFF;
}

.address-card {
    background-color: #191A1F;
    padding: 5%;
    border-radius: 5px;
}

.lbl {
    color: #000;
    font-family: 'Space Grotesk';
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    opacity: 0.5;
    padding-top: 20px !important;
}

.lbl-para {
    color: #000;
    font-family: 'Space Grotesk';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    opacity: 0.5;
}

.txt {
    background-color: #ffffff !important;
    border-radius: 3px !important;
    border: none;
}
.con-p-header-par{
    font-size: 20px;
    Font-family: 'Space Grotesk';
    margin-top: -20px;
}


#book-appointment .form-group ::placeholder{
    font-size: 12px !important;
}

.address-card p{
    font-size: 13px;
}
.address-card svg{
    margin: 14px;
}
@media (max-width: 1366px) {
    .contact-us-header {
        padding: 20% 0;
    }
}

@media (max-width: 1024px) and (min-height: 1366px) {
    .contact-us-header {
        padding: 35% 0;
    }

    .contact-us-form-container {
        display: block !important;
        height: 100%;
    }

    .contact-us-form-container > .p-2 {
        width: 100%;
        padding: 0 !important;
        margin: auto !important;
    }

    .contact-us-form-container > .p-2:first-child {
        width: 100%;
        padding: 0 !important;
        margin-bottom: 10% !important;
    }

    .contact-us-number-container > .col {
        margin-bottom: 5%;
    }
}

@media (max-width: 1024px) and (min-height: 600px) {
    .contact-us-header {
        padding: 10% 0;
    }

    .contact-us-form-container > .p-2:first-child {
        margin-right: 0;
        padding: 0 !important;
    }
}

@media (max-width: 768px) {
    .contact-us-header {
        padding: 45% 0 0 !important;
    }
    .contact-us-header {
        background-size: cover;
        height: 57vh;
        color: white;
        text-align: center;
        padding-top: 0%;
    }
    
    #contact-us-main-topic{
        font-size: 32px!important;
        width: 90%;
        margin: 0 auto;
        padding-bottom: 32px;
    }
    .contact-us-header .header-paragraph{
        margin-top: 0%!important;
        color: #FFF;
        text-align: center;
        font-family: 'Space Grotesk';
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 160% */
        width: 90% !important;
        margin: 0 auto;
    }
    .contact-us-number-container, .contact-us-form-container {
        display: flex !important;
        height: 100%;
        flex-direction: column-reverse;
        gap: 80px;
    }

    .contact-us-number-container {
        padding: 10% 0 !important;
        text-align: center;
    }

    .contact-us-form-container > .p-2 {
        width: 100%;
        padding: 0 !important;
        margin: auto !important;
    }

    .contact-us-header > .container {
        max-width: 100%;
        margin: 0;
        padding: 0;
    }

    .contact-us-number-container > .col {
        width: 100%;
        margin-bottom: 24px;
        text-align: center;
    }
    .c-us-hd{
        color: #000;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: 'Space Grotesk' !important;
        font-size: 24px !important;
        font-style: normal;
        font-weight: 700 !important;
        line-height: 42px !important; /* 175% */  
        letter-spacing: unset !important;    
        padding-bottom: 30px;
        padding-top: 40px;
    }
    .address-card {
        background-color: #191A1F;
        padding: 52px 24px;
        border-radius: 5px;
    }
    .contact-us-form-container {
        color: #8D8D8D;
        padding:40px 24px;
        background-color: #F1F1F1;
        justify-content: center;
    }
    .form-floating>.form-control ::placeholder{
        color: #000;
        font-family: 'Space Grotesk';
        font-size: 12px !important;
        font-style: normal;
        font-weight: 400;
        line-height: 14px; /* 116.667% */    
    }
    .contact-us-form-container form {
        margin-top: 0%;
    }
    .form-floating>.form-control input[type="text"], input[type="email"], input[type="tel"]{
        height: 46px !important;
        color: #000;
        font-family: 'Space Grotesk';
        font-style: normal;
        font-weight: 400;
        line-height: 14px; /* 116.667% */
    }

    .lbl-para{
        color: #000;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: 'Space Grotesk';
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 184.615% */
        margin-bottom: 24px;
        text-align: left;
    }
    .form-group {
        margin-bottom: 24px !important;
    }
    .contact-submit-button{
        color: #000;
        text-align: center;
        font-family: 'Integral CF';
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        line-height: 19.5px;
        text-transform: uppercase;
        letter-spacing: 1px;
    }
}

@media (max-width: 768px) and (min-height: 1024px) {
    .contact-us-header {
        padding: 40% 0;
    }
}

@media (max-width: 600px) {
    .contact-us-header {
        padding: 50% 0;
        background-position-x: 35%;
    }

    .contact-us-form-container > .p-2 > form > .d-flex {
        display: block !important;
    }

    .contact-us-form-container > .p-2 > form > .d-flex > .p-2 {
        width: 100% !important;
    }
    .con-p-header-par{
        font-size: 14px;
        Font-family: 'Space Grotesk';
        margin-top: 0px;
        padding: 0px 48px !important;
    }

    .address-card .heading{
        font-family: 'Space Grotesk' ;
        font-size: 21px;
        margin-top: 4%;
    }
    .address-card p {
        font-size: 16px;
    }
    .form-control{
        font-size: 12px !important;
    }
    .form-control .lbl{
        font-size: 12px !important;
    }
    .lbl {
        color: #000;
        font-family: 'Space Grotesk';
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 14px;
        opacity: 0.5;
        padding-top:10px !important;
    }

    .about-us-header #about-us-section-main-heading {
        font-size: 46px!important;
        letter-spacing: 2.88px;
        line-height: 58px;
        padding-bottom: 0;
    }
    ::placeholder{
        font-size: 12px !important;
    }
    .contact-us-number-container > .col > h3 {
        font-size: 21px;
    }
}

@media (max-height: 500px) {
    .contact-us-header {
        height: 100vh;
    }
}
