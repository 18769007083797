.blogs-container {
   padding-top: 5%;
   margin-bottom: 5%;
}

.blogs-container > .d-flex {
    height: 100%;
}

.blogs-container > .d-flex > .p-2:first-child {
    width: 65%;
}

.blogs-container > .d-flex > .p-2:last-child {
    width: 35%;
}

.blog-container {
    padding: 0;
    background-color: white;
    height: 100%;
}

.blogs-container > .d-flex > .p-2:last-child > .blog-container {
    height: 48.8%;
    margin-bottom: 3%;
}

.blog-img {
    opacity: 1;
    display: block;
    width: 100%;
    height: 100%;
    transition: .5s ease;
    backface-visibility: hidden;
    object-fit: cover;
}

.blog-name {
    transition: .5s ease;
    transform: translate(2%, -120%);
    -ms-transform: translate(2%, -120%);
    color: white;
    width: 100%;
    font-size: 36px;
}

.blogs-container > .d-flex > .p-2:last-child > .blog-container > .blog-name {
    transform: translate(5%, -120%);
    -ms-transform: translate(5%, -120%);
}

.blogs-container > .d-flex > .p-2:last-child > .blog-container .btn {
    width: 30%;
}

.blog-nav-link {
    font-family: 'Space Grotesk';
    font-size: 18px;
}

.blog-card-gray {
    background-color: #F6F6F6;
    padding: 5%;
}

.blog-button {
    background-color: #292929;
    color: #ffff;
    width: auto;
}

.blog-button .nav-link:hover {
    color: #292929 !important;
}

.card-date {
    color: #868686;
    font-family: 'Integral CF';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

.blog-para {
    color: #7F7F7F;
    font-family: 'Space Grotesk';
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
}
@media (min-width:1200px){
    .spc-blg-txt{
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        margin-bottom: 10%;
        min-height: 4.5vw;
    }
}
@media (max-width: 1024px) and (min-height: 1366px) {
    .blogs-container {
        height: 60vh;
    }

    .blogs-container > .d-flex > .p-2:last-child > .blog-container {
        height: 49.6%;
    }

    .blogs-container > .d-flex > .p-2:last-child > .blog-container .btn {
        width: 40%;
    }

    .blog-name {
        transform: translate(3%, -120%);
        -ms-transform: translate(3%, -120%);
    }
}

@media (max-width: 768px) {
    .blogs-container {
        height: 100%;
        padding: 40px 0;
    }

    .blogs-container > .d-flex {
        display: block !important;
    }

    .blogs-container > .d-flex > .p-2 {
        width: 100% !important;
        height: 50vh;
    }

    .blogs-container > .d-flex > .p-2:last-child {
        display: flex;
    }

    .blogs-container > .d-flex > .p-2:last-child > .blog-container {
        width: 50%;
        margin-right: 1.5%;
        height: 100%;
        margin-bottom: 0;
    }

    .blogs-container > .d-flex > .p-2:last-child > .blog-container:last-child {
        margin-right: 0;
        margin-left: 1.5%;
    }

    .blogs-container > .d-flex > .p-2:last-child > .blog-container .btn {
        width: 50%;
    }

    .blog-card-gray {
        background-color: #F6F6F6;
        padding: 40px 24px;
    }

    .blog-card-gray .card-heading {
        padding-top: 0%;
        color: #2A2A2A;
        font-family: Integral CF;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 133.333% */
        margin-bottom: 10px;
    }
    
}

@media (max-width: 600px) {
    .blogs-container > .d-flex > .p-2:last-child {
        display: block;
        height: 100%;
    }

    .blogs-container > .d-flex > .p-2:last-child > .blog-container {
        width: 100%;
        margin-right: 0;
        margin-bottom: 4%;
        height: 50vh;
    }

    .blogs-container > .d-flex > .p-2:last-child > .blog-container:last-child {
        margin-right: 0;
        margin-left: 0;
    }

    .blog-name {
        transform: translate(5%, -120%);
        -ms-transform: translate(5%, -120%);
        font-size: 26px;
        font-family: 'Space Grotesk';
        font-weight: bold;
        padding-right: 10%;
    }
}