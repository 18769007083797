#promotions{
    padding-left: 0;
}

.promotion-header {
    background: url("../images/promo.webp") no-repeat center;
    background-size: cover;
    height: 60vh;
    color: white;
    text-align: center;
    padding-top: 11%;
}

.promotions-heading {
    font-family: 'Integral CF';
    font-size: 48px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 2px;

}

.promo-img {
    width: 100%;
    height: 100%;
    transition: .5s ease;
    object-fit: cover;
}

.promo-container {
    transition: .5s ease;
    color: white;
    width: 100%;
    font-size: 36px;
}

.promotion-card {
    overflow: hidden;
    position: relative;
    height: 500px;
    margin-bottom: 25px;
}

.promotion-card-container {
    height: 30vw;
}

.promotion-header-para {
    font-family: 'Space Grotesk';
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
    width: 740px;
    margin-top: 40px !important;
}

.promo-heading-tag {
    color: #ffffff;
    font-family: 'Integral CF';
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 2.22px;
    font-size: 34px;
}

.read-btn {
    background: #FFDDA9;
    border: none;
    color: #292929;
}

.read-btn:hover {
    background: #292929;
}

.read-more-link-prmbtn {
    padding-left: 0%;
}

.align-left {
    justify-content: start;
    transform: translate(2%, -120%);
    -ms-transform: translate(2%, -120%);
    padding: 0% 0% 2% 5%;
}
  
.align-right {
    display: grid;
    text-align: end !important;
    justify-content: flex-end;
    transform: translate(-2%, -120%);
    padding: 0% 5% 2% 0%;
}

.tax {
    color: #FFF;
    font-family: "Space Grotesk";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
}

.align-right > .right-margin {
    margin-right: 0;
}

.modal-promo-img {
    object-fit: cover;
}

.modal-close-btn {
    position: absolute;
    top: 0;
    right: 10px;
    background: none;
    border: none;
    font-size: 40px;
    cursor: pointer;
    color: #fff;
}

.modal-promo-container {
    color: #fff;
    padding-left: 5%;
    position: relative;
}

.modal-promotion-header-para {
    font-family: 'Space Grotesk';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
    width: 900px;
    margin-top: 1rem;
    text-transform: capitalize;
}

.modal-tax {
    color: #FFF;
    font-family: "Space Grotesk";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
}

.modal-dialog {
    max-width: 90%;
    margin-top: 2vw;
    background: transparent;
}

.modal-body {
    padding: 0;
    margin-bottom: -5%;
    background-color: #292929 !important;
}

.cnt {
    background-size: cover;
    background-position: center;
    padding-top: 5%;
    padding-bottom: 5%;
    position: relative;
}

.pop-button {
    font-family: 'Integral CF';
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px;
    align-items: center;
    width: 160px !important;
    height: 40px;
    background: #DAA676;
    border: none;
    margin-top: 0.5rem!important;
}

.pop-button:hover {
    background-color: #ffffff !important;
    color: #292929 !important;
}

.promo-gradient {
    height: 500px;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.promo-gradient-modal {
    background: linear-gradient(0deg, #000 10%, rgba(0, 0, 0, 0.00) 140%);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.al-left {
    background: linear-gradient(90deg, #000 0%, rgba(0, 0, 0, 0.00) 100%);
}

.al-right {
    background: linear-gradient(270deg, #000 0%, rgba(0, 0, 0, 0.00) 100%);
}

.call {
    margin-top: 7px;
}

.call-numb {
    text-decoration: none;
    color: #292929;
}

.call-numb:hover {
    text-decoration: none;
    color: #292929;
}

.read-more-link-promo {
    align-items: center;
}
.sp-p-prs{
    text-transform: capitalize !important;
    font-weight: 700;
    font-family: 'Space Grotesk';
}
@media (max-width:678px){
    .promotion-header {
        background: url("../images/promo.webp") no-repeat center;
        background-size: cover;
        height: 57vh;
        color: white;
        text-align: center;
        padding-top: 49%;
 
    }    

    .promotions-heading {
        color: #FFF;
        text-align: center;
        leading-trim: both;
        text-edge: cap;
        font-family: Integral CF;
        font-size: 32px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 1.92px;

    }

    #promotions{
        padding-left: 24px !important;
        padding-right: 24px !important;
    }

    .promo-heading-tag, .promotion-header-para{
        width: fit-content;
    }

    #promotions .read-more-link{
        width: fit-content;
    }

    #promotion p {
        width: fit-content;
    }
    .tax {
        color: #FFF;
        font-family: "Space Grotesk";
        font-size: 12px;
        font-style: normal;
        font-weight: 300;
        line-height: 140%;
        text-align: left;
    }
    
    #promotions .align-right{
        justify-items: end;
        text-align: right;
    }
    #promotions .align-right p{
        text-align: right !important;
    }
    .promo-container {
        padding: 0px 24px;
        margin-top: -14%;
    }
    .align-left {
        justify-content: start;
        transform: translate(0%, -100%);
        -ms-transform: translate(0%, -100%);
    }
    .align-right {
        transform: translate(-0%, -100%);
    }
    .align-left .promotion-header-para{
        text-align: left;
    }
    .align-right .promotion-header-para{
        text-align: right;
    }

    .promotion-card .read-more-link button{
        height: 44px !important;
    }
    .promo-heading-tag {
        color: #ffffff;
        font-family: 'Integral CF';
        font-style: normal;
        font-weight:300;
        line-height: 26px;
        letter-spacing: 2.22px;
        font-size: 37px;
        line-height: 47px;
        font-size: 37px;
    }

    .model-nm-sp-dv{font-size: unset;}

    .modal-promo-container {
        color: #fff;
        padding-left: 5%;
        position: relative;
        padding-top: 22%;
        font-size: 32px;
    }

    .promotion-header-para {
        font-family: 'Space Grotesk';
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 25px;
    }

    .modal-promotion-header-para {
        font-family: 'Space Grotesk';
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 25px;
        padding-top: 8%;
    }
    .modal-dialog {
        max-width: 88%;
        margin-top: 2vw;
        background: transparent;
        margin: 0 auto;
    }
    .pop-button {
        font-family: 'Integral CF';
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 40px;
        align-items: center;
        width: 160px !important;
        height: 50px;
        background: #DAA676;
        border: none;
        margin-top: 0.5rem!important;
    }

    .read-more-link-promo a{
        display: block;
        width: 100% !important;
        text-decoration: none;
    }

    .read-more-link-promo  button{ 
        display: block;
        width: 95% !important;
        color: #292929;
    }
    .promotion-card {
        overflow: hidden;
        position: relative;
        height: 75vh;
        margin-bottom: 25px;
    }
    .promo-gradient {
        height: 75vh;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }
    .promo-img {
        width: 100%;
        height: 75vh !important;
        transition: .5s ease;
        object-fit: cover;
    }
    .spc-model-popup-nme{
        text-align: left;
        font-family: Integral CF;
        font-size: 20px;
        font-weight: 400;
        line-height: 44px;
        letter-spacing: 0.06em;
        text-align: left;

    }
}