.login-header {
    background-image: url("../images/login-img.webp");
    background-position: center;
    background-size: cover;
    position: relative;
    height: 100vh;
    padding: 12% 0;
    background-color: #0a0a0a;
    color: #f4f4f4;
}

.login-component {
    width: 28%;
    background-color: #f4f4f4;
    color: #0a0a0a;
    padding: 3%;
    text-align: left;
    scroll-snap-align: none !important;
}

.login-component > .heading {
    margin-bottom: 5%;
}

.login-button {
    width: 100%;
    padding: 2%;
    margin-top: 5%;
}

@media (max-width: 1024px) and (min-height: 600px) {
    .login-component{
        width: 90%;
        margin-top: 40%;
        padding: 5%;
    }
    .login-component > .heading{
        font-size: 20px;
        margin-bottom: 10%;
    }
 
    .login-component > form > .form-group > label{
        font-size: 16px;
    }
}