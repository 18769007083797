   * {
            margin: 0;
            padding: 0;
            box-sizing: border-box;
        }

        body,
        html {
            height: 100%;
        }

        .back-color {
            background-color: #080809;
        }

        .container-background-image {
            background-color: #080809;
            display: flex;
            padding-top: 145px;
            justify-content: center;
            align-items: FLEX-START;
            height: 100vh;
            background: url('https://res.cloudinary.com/dff4rbfkn/image/upload/v1727857693/rolls-royce-black-badge-ghost-car-designboom600_3_cybaw9.png') no-repeat center center/cover;
        }

        .content {
            text-align: center;
        }

        .logo {
            font-size: 24px;
            font-weight: bold;
            margin-bottom: 20px;
        }

        .logo img {
            width: 50px;
        }

        .title {
            color: #FFF;
            text-align: center;
            font-family: "Integral CF Regular";
            font-size: 64px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            padding-top: 10vw;
        }

        .description {
            font-size: 16px;
            margin: 20px 0;
            line-height: 1.6;
            padding-bottom: 5vw;
            width: 60%;
            padding-top: 20px;
            margin: 0 auto;
            color: #FFF;
            text-align: center;
            font-family: "Space Grotesk";
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            /* 150% */

        }

        .rsvp-btn {
            padding: 15px 30px;
            background-color: white;
            color: black;
            font-size: 16px;
            border: none;
            cursor: pointer;
            text-transform: uppercase;
            transition: background-color 0.3s ease;
            display: flex;
            padding: 9px 120px;
            justify-content: center;
            align-items: center;
            gap: 10px;
            color: #080809;
            text-align: center;
            leading-trim: both;
            text-edge: cap;
            font-family: "Integral CF Regular";
            margin: 0 auto;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            text-decoration: none;
            line-height: normal;
            width: fit-content;
        }


        .rsvp-btn:hover {
            background-color: #ff4500;
            color: white;
        }

        .card-container {
            padding: 80px 72px;
            max-width: 1650px;
            margin: 0 auto;

        }

        .black-car-container {
            max-width: 1650px;
            margin: 0 auto;
            padding-left: 72px;
        }

        .card-container .crd-section-ttl {
            color: #080809;
            font-family: "Integral CF Regular";
            font-size: 32px;
            font-style: normal;
            font-weight: 500;
            line-height: 40px;
            /* 125% */
            padding-bottom: 16px;
        }

        .card-container .crd-section-detail {
            color: #000;
            font-family: "Space Grotesk";
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            /* 150% */
            width: 35%;
            padding-bottom: 40px;
        }

        .crd-wrapper {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
        }

        .crd-wrapper .single-card {
            width: 23%;
        }

        .crd-wrapper .single-card img {
            width: 100%;
            height: 182px;
            object-fit: cover;
        }

        .crd-wrapper .single-card .img-description {
            padding: 40px 20px;
            background: #F9F9F9;
        }

        .crd-wrapper .single-card .img-description h3 {
            color: #080809;
            font-family: "Integral CF Regular";
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            margin-bottom: 24px;
        }

        .crd-wrapper .single-card .img-description p {
            color: #080809;
            font-family: "Space Grotesk";
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
            /* 133.333% */
        }

        .black-car-section {
            background: var(--Linear--1, linear-gradient(0deg, #080809 0%, #151515 100%));
            padding: 190px 0;
            position: relative;
            overflow: hidden;
        }

        .black-car-section .black-car-container h3 {
            color: #FFF;
            font-family: "Integral CF Regular";
            font-size: 48px;
            font-style: normal;
            font-weight: 500;
            line-height: 56px;
            width: 50%;
            padding-bottom: 60px;
            /* 116.667% */
        }

        .black-car-section .black-car-container p {
            color: #FFF;
            font-family: "Space Grotesk";
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            width: 46%;
            /* 150% */
        }

        .black-car-section .abs-img {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: -30%;
            width: 70%;
            overflow: hidden;
        }

        .map-area {
            display: flex;
            justify-content: left;
            align-items: center;
        }

        .map-area .rgt-cont {
            display: flex;
            justify-content: left;
            align-items: left;
            flex-direction: column;
            width: 100%;
            padding-left: 90px;
        }

        .map-area .rgt-cont h3 {
            color: #191A1E;
            font-family: "Integral CF Regular";
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            padding-bottom: 43px;
        }

        .map-area .flx-number {
            display: flex;
            gap: 20px;
            padding-bottom: 20px;
            border-bottom: 1px solid #191A1F;
            width: 50%;
            margin-bottom: 20px;
        }

        .map-area .flx-number-no {
            border: none;
            padding-bottom: 0;
            margin-bottom: 0;
        }

        .map-area .flx-number h4 {
            color: #191A1F;
            font-family: "Integral CF Regular";
            font-size: 15px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            min-width: 150px;
        }

        .map-area .flx-number a {
            color: #191A1F;
            font-family: "Space Grotesk";
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 15px;
            text-transform: capitalize;
            text-decoration: none;
        }

        .footer {
            padding: 60px 72px;
            background: #080809;
        }

        .footer .ftr-wrapper {
            max-width: 1650px;
            margin: 0 auto;
        }

        .footer .ftr-wrapper svg {
            margin-bottom: 26px;
        }

        .ftr-para {
            color: #FFF;
            font-family: "Space Grotesk";
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            width: 50%;
            padding-bottom: 40px;
            /* 150% */
        }

        .ftr-ico {
            display: flex;
            gap: 30px;
            padding-bottom: 10px;
            border-bottom: 1px solid #fff;
        }

        .cpy-rgt {
            color: #FFF;
            font-family: "Space Grotesk";
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
            padding-top: 40px;
            /* 133.333% */
        }

        .back-arr {
            font-size: 32px;
            position: absolute;
            top: 40px;
            left: 40px;
        }

        .ftr-ico svg {
            height: 20px;
            width: 20px;
        }

        @media(max-width:768px) {
            * {
                margin: 0;
                padding: 0;
                box-sizing: border-box;
            }

            body,
            html {
                height: 100%;
            }

            .back-color {
                background-color: #080809;
            }

            .container-background-image {
                background-color: #080809;
                display: flex;
                padding-top: 115px;
                justify-content: center;
                align-items: FLEX-START;
                height: 100vh;
                background: url('https://res.cloudinary.com/dff4rbfkn/image/upload/v1727865731/d4ab6e4792c8ab79608512a8aad38374_ldjuhu.jpg') no-repeat center center/cover;
            }

            .content {
                text-align: center;
            }

            .logo {
                font-size: 24px;
                font-weight: bold;
                margin-bottom: 20px;
            }

            .logo img {
                width: 50px;
            }

            .title {
                color: #FFF;
                text-align: center;
                font-family: "Integral CF Regular";
                font-size: 45px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                padding-top: 10vw;
                padding-right: 25px;
                padding-left: 25px;
            }

            .description {
                font-size: 16px;
                margin: 20px 0;
                line-height: 1.6;
                padding-bottom: 55vw;
                width: 86%;
                padding-top: 20px;
                margin: 0 auto;
                color: #FFF;
                text-align: center;
                font-family: "Space Grotesk";
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;
                /* 150% */

            }

            .rsvp-btn {
                padding: 15px 30px;
                background-color: white;
                color: black;
                font-size: 16px;
                border: none;
                cursor: pointer;
                text-transform: uppercase;
                transition: background-color 0.3s ease;
                display: flex;
                padding: 9px 120px;
                justify-content: center;
                align-items: center;
                gap: 10px;
                color: #080809;
                text-align: center;
                leading-trim: both;
                text-edge: cap;
                font-family: "Integral CF Regular";
                margin: 0 auto;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                text-decoration: none;
                line-height: normal;
                width: fit-content;
            }


            .rsvp-btn:hover {
                background-color: #ff4500;
                color: white;
            }

            .card-container {
                padding: 60px 25px;
                max-width: 1650px;
                margin: 0 auto;

            }

            .black-car-container {
                max-width: 1650px;
                margin: 0 auto;
                padding-left: 0;
            }

            .card-container .crd-section-ttl {
                color: #080809;
                font-family: "Integral CF Regular";
                font-size: 24px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                /* 125% */
                padding-bottom: 16px;
            }

            .card-container .crd-section-detail {
                color: #000;
                font-family: "Space Grotesk";
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 16px;
                /* 150% */
                width: 100%;
                padding-bottom: 40px;
            }

            .crd-wrapper {
                display: flex;
                align-items: flex-start;
                flex-direction: column;
                justify-content: space-between;
                gap: 40px;
            }

            .crd-wrapper .single-card {
                width: 100%;
            }

            .crd-wrapper .single-card img {
                width: 100%;
                height: 182px;
                object-fit: cover;
            }

            .crd-wrapper .single-card .img-description {
                padding: 40px 20px;
                background: #F9F9F9;
            }

            .crd-wrapper .single-card .img-description h3 {
                color: #080809;
                font-family: "Integral CF Regular";
                font-size: 18px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                margin-bottom: 24px;
            }

            .crd-wrapper .single-card .img-description p {
                color: #080809;
                font-family: "Space Grotesk";
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 16px;
                /* 133.333% */
            }

            .black-car-section {
                background: var(--Linear--1, linear-gradient(0deg, #080809 0%, #151515 100%));
                padding: 60px 25px;
                position: relative;
                overflow: hidden;
            }

            .black-car-section .black-car-container h3 {
                color: #FFF;
                font-size: 24px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                width: 100%;
                /* 116.667% */
            }

            .black-car-section .black-car-container p {
                color: #FFF;
                font-family: "Space Grotesk";
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 16px;
                width: 100%;
                padding-bottom: 300px;
            }

            .black-car-section .abs-img {
                position: absolute;
                top: 70%;
                transform: translateY(-50%);
                right: -56%;
                width: 627px;
                overflow: hidden;
            }

            .map-area {
                display: flex;
                justify-content: left;
                align-items: center;
                flex-direction: column-reverse;
            }

            .map-area .rgt-cont {
                display: flex;
                justify-content: left;
                align-items: left;
                flex-direction: column;
                width: 100%;
                padding-left: 25px;
                padding-right: 25px;
                padding-top: 40px;
            }

            .map-area .rgt-cont h3 {
                color: #191A1E;
                font-family: "Integral CF Regular";
                font-size: 24px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                padding-bottom: 43px;
            }

            .map-area .flx-number {
                display: flex;
                gap: 20px;
                padding-bottom: 20px;
                border-bottom: 1px solid #191A1F;
                width: 100%;
                margin-bottom: 20px;
            }

            .map-area .flx-number-no {
                border: none;
                padding-bottom: 0;
                margin-bottom: 40px;
            }

            .map-area .flx-number h4 {
                color: #191A1F;
                font-family: "Integral CF Regular";
                font-size: 15px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                min-width: 150px;
            }

            .map-area .flx-number a {
                color: #191A1F;
                font-family: "Space Grotesk";
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 15px;
                text-transform: capitalize;
                text-decoration: none;
            }

            .footer {
                padding: 40px 25px;
                background: #080809;
                margin-top: 25px;
            }

            .footer .ftr-wrapper {
                max-width: 1650px;
                margin: 0 auto;
            }

            .footer .ftr-wrapper svg {
                margin-bottom: 26px;
            }

            .ftr-para {
                color: #FFF;
                font-family: "Space Grotesk";
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;
                width: 100%;
                padding-bottom: 40px;
                /* 150% */
            }

            .ftr-ico {
                display: flex;
                gap: 30px;
                padding-bottom: 10px;
                border-bottom: 1px solid #fff;
            }

            .ftr-ico svg {
                height: 20px;
                width: 20px;
            }

            .cpy-rgt {
                color: #FFF;
                font-family: "Space Grotesk";
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 16px;
                padding-top: 40px;
                /* 133.333% */
            }

            .back-arr {
                font-size: 32px;
                position: absolute;
                top: 55px;
                left: 20px;
            }

            .back-arr svg {
                height: 30px !important;
                width: 30px !important;
            }

            .gmap_canvas {
                overflow: hidden;
                position: relative;
                height: 240px !important;
                width: 100% !important;
                background: #fff;
            }

            iframe {
                width: 100% !important;
                height: 240px !important;
                min-width: 350px !important;
            }
        }