.blog-detail-container {
    padding: 7% 0;
}

.blog-detail-container > .p-2 > p {
    margin: 5% 0;
}

.blog-detail-container > .p-2 > p:first-child {
    margin-top: 0;
}

.blog-detail-container > .p-2 > img {
    width: 100%;
    height: 50vh;
    object-fit: cover;
}

.blog-detail-container > .p-2:first-child {
    width: 70%;
    margin-right: 5%;
    padding-top: 80px !important;
}

.blog-detail-container > .p-2:nth-child(2) {
    width: 30%;
    padding-top: 80px !important;
}

.latest-update-blog-heading {
    font-size: 18px;
    color: #111111;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: 'Space Grotesk';
    font-weight: bold;
}

.latest-update-blog-image-container {
    width: 30%;
}

.latest-update-blog-image {
    height: 100%;
    width: 100%;
    max-height: 15vh;
    object-fit: cover;
}

.latest-blog-container {
    margin-bottom: 10%;
}

.sin-bg-hd {
    font-family: 'Integral CF';
    font-size: 48px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 2px;
    color: #111111;
}

@media (max-width: 1024px) and (min-height: 1366px) {
    .latest-update-blog-container > .p-2:nth-child(2) > .btn {
        width: 75%;
        margin-top: 10%;
    }
}

@media (max-width: 768px) {
    .blog-detail-container {
        display: block !important;
        height: 100%;
    }

    .blog-detail-container > .p-2 {
        width: 100% !important;
        margin: 5% 0 0;
    }

    .latest-update-blog-image {
        max-height: 21vh;
    }

    .latest-update-blog-container {
        height: 20vh;
    }
}

@media (max-width: 600px) {
    .latest-update-blog-container > .p-2:nth-child(2) > .btn {
        width: 100%;
        margin-top: 10%;
    }

    .blog-header {
        padding: 50% 0 0;
        height: 75vh;
    }

    .sin-bg-hd{
        margin: auto;
        text-align: left;
        width: 100%;
        font-size: 22px;
        padding-top: 40px;
        letter-spacing: 1px;
    }

    .sp-bg-h{
        padding-top: 65% !important;
    }

    .ce-paragraph {
        color: #4F4F4F;
        font-family: 'Space Grotesk';
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 140.4%;
        padding: auto 24px !important;
    }

    .blog-detail-container > .p-2:first-child {
        width: 70%;
        margin-right: 5%;
        padding: 0 24px !important;
        padding-top: 40px !important;
    }

    .latest-blog-container .blog-para{
        text-align: left !important;
    }

    .latest-blog-container .white-background-button{
        height: 50px !important;
        width: 136px !important;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .latest-blog-container .card-date {
        color: #868686;
        font-family: 'Integral CF';
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        margin: 0;
    }

    .latest-blog-container .d-flex{
        justify-content: center;
        align-items: center;
    }
    .spc-btn-blg-im{
        margin-left: 0 !important;

    }
    .spc-btn-blg-im .nav-link {
        color: #fff;
        text-align: center;
        font-family: 'Integral CF';
        font-size: 17px;
        font-style: normal;
        font-weight: 400;
        line-height: 17px;
        background: #292929 !important;
        padding: 10%;
    }
    .spc-btn-blg-im .nav-link:hover {
        color: #292929;
        text-align: center;
        font-family: 'Integral CF';
        font-size: 17px;
        font-style: normal;
        font-weight: 400;
        line-height: 17px;
        background: #FFDDA9 !important;
        padding: 10%;
    }
    .spc-btn-blg-im2{
        width: 100%;
        display: block !important;
    }
}
@media(min-width:1200px){
    .blog-detail-container {
        padding: 7% 14% 3%;
    }
}