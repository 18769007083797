.crew-header {
    background: url("../images/crew.webp") no-repeat center;
    background-size: cover;
    height: 60vh;
    padding-top: 10%;
}

.crew-main-heading {
    color: #FFF;
    text-align: center;
    font-family: 'Integral CF';
    font-size: 48px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 2px;
}

#our-crew-intro-paragraph {
    color: #FFF;
    text-align: center;
    font-family: 'Space Grotesk';
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.crew-heading {
    font-size:37px;
    line-height: 44px;
}
.management-crew .crew-heading {
    font-size: 37px;
    line-height: 80px;
}
.management-crew .crew-name{
    font-size: 24px;
    line-height: 29px;
}
.management-crew .cw-p{
    font-size: 18px;
    line-height: 23px;
}
.crew-container {
    text-align: center;
    padding: 3% 0;
}

.crew-container > .container > .page-heading {
    font-family: "Integral CF Demi Bold";
}

.image {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    margin: auto;
    object-fit: cover;
}

.crew-image {
    width: 140px;
    height: 140px;
    border-radius: 50%;
    margin: auto;
    object-fit: cover;
}

.no-image {
    background-color: #D9D9D9;
}

.crew-data-container {
    margin-top: 8%;
    margin-top: 8%;
    display: flex;
    text-align: center;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

}

.crew-container > .container > .row > .col {
    margin-top: 2%;  
}

.crew-header p {
    text-align: center;
    font-family: 'Space Grotesk';
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 68%;
}

.crew-name {
    font-size: 17px;
}
.cw-p{
    font-size: 13px;
}
.team-leads {
    padding: 0% 10% 0% 10% ;
}

.crew-header > .h1 {
    text-align: center;
    font-family: 'Integral CF';
    font-size: 48px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 2px;
}

@media (max-width: 768px) {

    #our-crew-main-heading{
        font-size: 32px!important;
        color: #FFF;
        text-align: center;
        font-family: Integral CF;
        font-size: 32px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 1.92px;
        width: 100%;
        margin: 0 auto;
        padding-bottom: 32px;
    }

    #our-crew-intro-paragraph{
        margin-top: 0%!important;
        color: #FFF;
        text-align: center;
        font-family: 'Space Grotesk';
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 160% */
        width: 91% !important;
        margin: 0 auto;
    }

    .crew-header {
        height: 57vh;
        padding: 37% 0 0;
    }

    .crew-container {
        padding: 10% 0;
    }

    .crew-container > .container > .row > .col {
        width: 100%;
    }


    .crew-container > .container > .page-heading {
        font-family: "Integral CF Demi Bold";
        color: #000;
        font-family: 'Integral CF';
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: normal; /* 333.333% */
        letter-spacing: 1.44px;
        padding-bottom: 40px;
    }

    .managment-container{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-left: 0 !important;
        margin-right: 0 !important;
        row-gap: 18px;
        justify-content: center;
    }

    .crew-container > .container > .row > .col {
        width: 49% !important;
    }
    .managment-container img{
        width: 115px;
        margin: 0 auto;
        height: 115px !important;
    }
    .team-leads img{
        width: 96px !important;
        height: 96px !important;
    }
    .crew-container > .container > .row > .col img {
        width: 96px;
        margin: 0 auto;
       height: 96px !important;
    }
    .crew-data-container {
        margin-top: 20px;
    }
    .crew-name {
        color: #000;
        text-align: center;
        leading-trim: both;
        text-edge: cap;
        font-family: Integral CF;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 1.08px;
    }
    .cw-p{
        margin: 0 !important;
    }
    .image {
        height: 115px;
        width: 115px;
    }
    .sp-crv{
        padding-top: 0px !important;
    }
    .cw-p{
        font-size: 14px;
    }

    .team-leads .cw-p{
        font-size: 13px;
    }

    .team-leads .crew-name{
        font-size: 15px;
    }

    .team-leads .image{
        height: 108px;
        width: 108px;
    }
    .management-crew .crew-name {
        font-size: 18px;
        font-weight: 600;
    }
    .management-crew .cw-p{font-size: 14px;}
}
