.about-us-header {
    background: url("../images/f1.webp") no-repeat center;
    background-size: cover;
    height: 100vh;
    color: white;
    text-align: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

#about-us-header-paragraph-intro {
    margin: 0px;
    font-size: 20px;
    margin-top: 32px;
    line-height: 24px;
}

.about-us-header > .d-flex {
    margin-top: 3%;
}

.about-us-header > .d-flex > .p-2 {
    font-size: 20px;
}

.about-us-container {
    margin-top: 10%;
    overflow: hidden;
}

.about-us-container > .p-2 {
    width: 70%;
}

.about-us-container > .p-2:first-child {
    padding: 2% 0 !important;
    position: relative;
}

.about-us-container > .p-2 > img {
    width: 100%;
    height: 100%;
}

.about-us-detail-container {
    width: 30vw;
    margin-top: 8%;
    font-size: 15px;
}

.about-us-image {
    width: 58rem;
    display: flex;
    margin-left: -340px;
}

.ceo-container {
    height: 100vh;
    text-align: left;
    padding: 10%;
}

.page-heading-main {
    text-align: center;
    font-family: 'Integral CF';
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 48px;
    letter-spacing: 2px;
}

.header-heading-main {
    font-family: 'Integral CF';
    font-size: 60px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 2.88px;
}

.header-heading-count {
    text-align: center;
    font-family: 'Space Grotesk';
    font-size: 72px;
    font-style: normal;
    font-weight: 700;
    line-height: 100px;
}

.about-para {
    padding: 5%;
    padding-bottom: 0;
}

.why-driveline-heading{
    text-align: left;
    font-family: 'Integral CF';
    font-size: 37px;
    font-style: normal;
    font-weight: 400;
    line-height: 62px;
    letter-spacing: 2.22px;
}

.about-car-img {
    background-color: #080809;
}

.vm-container {
    height: 34vh;
    background-color: #000000;
    padding: 0px 12% 0px 12%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.vm-container:hover {
    border-bottom: 14px solid #DAA676;
    transition: 0.5s;
}

.vm-row {
    margin-top: 10%;
    margin-bottom: 10%;
    padding: 0 !important;
}

.mission {
    color: #FFDDA9;
    font-family: 'Integral CF';
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 2px;
    margin-bottom: 6%;
}

.mission-para {
    color: #FFF;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 23px;
}

.founder-sig {
    color: #03008E;
    font-family: 'Photoshoot';
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 62px;
    letter-spacing: 1px;
    margin-top: 10%;
}

.founder-container {
    border-bottom: #DAA676 solid 120px;
}

.founder-image {
    flex-shrink: 0;
    width: 27rem;
    margin-bottom: -150px;
}

.founder-img-container {
    width: 43%;
}

.meet-crew {
    background: url("../images/crew-bg.webp") no-repeat center;
    background-size: cover;
    color: white;
    text-align: center;
    padding: 15% 0;
    margin: 5% 3%;
    margin-top: 0;
}

.crew-button {
    margin-top: 2%;
    background: #DAA676;
    border: none;
}

.crew-button:hover {
    border-color: #292929;
}

.award {
    position: absolute;
    top: 50%;
    left: 5%;
    max-width: 25vw;
    color: #FFF;
    font-family: 'Integral CF';
    font-size: 37px;
    font-style: normal;
    font-weight: 400;
    line-height: 62px;
    letter-spacing: 2px;
}

.awart-img-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.award-block {
    max-width: 35vw;
}

.award-gradient {
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.98) 91.75%);
}
.awards-container{
    padding: 0 !important;
}
.awards-container .p-5{
    padding: 0 !important;
}

.sp-hdr-hdr-mn{
    font-size: 37px;
}

.sp-hdr-hdr-para{
    font-size: 18px;
    width: 30%;
    line-height: 30px;
}

.crw-sp-btn{
    padding-top: 14px !important;
    padding-bottom: 14px !important;
}


@media (max-width: 1024px) and (min-height: 1366px) {

    .ceo-container {
        height: 60vh;
    }

    .ceo-container > .p-2 > .testimonial-person-container {
        margin-top: 10%;
    }

    .about-us-header {
        background-position: left;
    }

    .ceo-container, .vision-mission-container, .about-us-header > .d-flex {
        display: block !important;
        height: 100%;
    }

    .ceo-container > .p-2:first-child {
        width: 100%;
        margin-bottom: 10%;
    }

    .ceo-container > .p-2:last-child {
        width: 100%;
        margin: auto;
    }

    .ceo-container iframe {
        min-height: 30vh;
    }

    .about-us-container {
        display: block !important;
    
    }

    .about-us-container > .p-2 {
        width: 100%;
        padding: 0 !important;
    }

    .about-us-detail-container {
        position: relative;
        width: 100%;
        padding: 0;
    }
}

@media (max-width: 1024px) and (min-height: 600px) {
    .about-us-header > .d-flex {
        margin-top: 5%;
        width: 100%;
        text-align: center;
        margin-left: 15%;
    }

    .about-us-header > .d-flex > .p-2{
        font-size: 24px;
    }
    .about-us-header > .d-flex > .p-2 > span{
        margin-left: 4%;
        font-size: 45px;
    }

    .about-us-container {
        min-height: 70vh;
    }

    .about-us-container > .p-2 > img {
        height: auto;
    }

    .ceo-container {
        padding: 0 5%;
        margin-bottom: 5%;
    }
}

@media (max-width: 768px) {
    .about-us-header {
        padding: 25% 0;
    }

    .ceo-container, .vision-mission-container, .about-us-header > .d-flex {
        display: block !important;
        height: 70%;
    }

    .ceo-container > .p-2:first-child {
        width: 100%;
        margin-bottom: 10%;
    }

    .vision-mission-container {
        padding: 10% 0 !important;
    }

    .vision-mission-container > div {
        margin: 0 !important;
        margin-bottom: 12% !important;
    }

    .about-us-header {
        background-position-x: 10%;
    }

    .about-us-container {
        display: block !important;
    }

    .about-us-container > .p-2 {
        width: 100%;
        padding: 0 !important;
    }

    .about-us-detail-container {
        position: relative;
        width: 100%;
        padding: 0;
    }

    #about-us-detail-paragraph{
        text-align: left!important;
        font-weight: 500;
        line-height: 28px;
    }

    .ceo-container > .p-2:last-child {
        width: 100%;
        margin: auto;
    }

    .ceo-container iframe {
        min-height: 40vh;
    }

    .ceo-container {
        margin-top: -25%;
    }

    .mission-para {
        width: 100%;
    }
    .sp-hdr-hdr-mn{
        font-size: unset;
    }

    .sp-hdr-hdr-para{
        font-size: 16px,;
        width: unset;
        line-height: unset;
    }
    .crw-sp-btn{
        padding-top: unset !important;
        padding-bottom: unset !important;
    }
    .mission-para {
        color: #FFF;
        font-size: 15px;
        font-style: normal;
        font-weight: 300;
        line-height: 23px;
    }
    #about-final-fs{
        font-size: 40px !important;
        line-height: 32px;
        padding-bottom: 0;
        
    }
}

@media (max-width: 768px) and (min-height: 1024px) {
    .about-us-header {
        padding: 40% 0;
    }

   
}

@media (max-width: 600px) {
    .about-us-header {
        padding: 22% 0 0;
    }
}

@media (max-height: 500px) {
    .about-us-header {
        height: 150vh;
    }
}

@media only screen and (max-width: 768px) {

    #about-us-section-main-heading{
        font-size: 40px!important;
        margin-top: 0%;
        color: #FFF;
        text-align: center;
        font-style: normal;
        font-weight: 400;
        line-height: 34px; /* 275% */
        letter-spacing: 0.96px;
        width: 90%;
        margin: 0 auto;
        padding-bottom: 0;
    }

    #about-us-header-paragraph-intro{
        margin-top: -1%!important;
        color: #FFF;
        text-align: center;
        font-family: 'Space Grotesk';
        font-size: 17px;
        font-style: normal;
        font-weight: 300 !important;
        line-height: 28px; /* 164.706% */
        padding-top: 34px;
        width: 90% !important;
    }

    .mobile {
      display: block;
      box-sizing: content-box;
      height: 70px;
      font-size: 25px;
      margin-left: 5%;
      font-size: 24px;
      margin-top: 35%;     
    }

    .mobile-slider > span{
        display: block;
        height: 120%;
        padding-left: 10px;
        text-align: center;
        padding-top: 10%;
        animation: spin_words 6s infinite;
    }

    .mobile-slider{
        overflow: hidden;
        height: 140%;
        margin-top: 10%;
        margin-bottom: 10%;
    }
    
    .mobile > .wrapper{
        width: 100%;
        left: 50%;
        height: 100%;
        font-size: 56px;
        overflow: hidden;
    }

    .mobile > .wrapper > span{
        display: block;
        font-family: "Integral CF";
        height: 100%;
        animation: animate_numbers 6s infinite;
    }

    @keyframes animate_numbers{
        0%{
            transform: translateY(100%);
        }
        30%{
            transform: translateY(0%);
        }
        35%{
            transform: translateY(-100%);
        }
        65%{
            transform: translateY(-120%);
        }
        70%{
            transform: translateY(-200%);
        }
        100%{
            transform: translateY(-220%);
        }
    }


@keyframes spin_words{
    0%{
        transform: translateY(100%);
    }
    30%{
        transform: translateY(0%);
    }
    35%{
        transform: translateY(-100%);
    }
    65%{
        transform: translateY(-120%);
    }
    70%{
        transform: translateY(-200%);
    }
    100%{
        transform: translateY(-220%);
    }
}

    .about-us-header> .d-flex{
        margin-top: -40%;
    }
    .about-us-header> .d-flex > .p-2{
        display: none;
    }




    .about-us-container {
        display: block !important;
        margin-top: 40px !important;
        margin-bottom: 40px !important;
        padding-left: 24px !important;
        padding-right: 24px !important;
    }
    .about-para {
        padding: 40px 24px;
        padding-bottom: 100px;
    }

    .why-driveline-heading {
        color: #000;
        font-family: Integral CF;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 48px; /* 258.333% */
        letter-spacing: 1.44px;
    }

    .about-us-detail-container {
        width: 100%;
        margin-top: 32px;
        font-size: 20px;
        position: relative;
    }
    .about-us-image {
        width: 80% !important;
        display: flex;
        margin-left: 0;
    }

    .about-car-img {
        background-color: #080809;
        max-width: 100%;
        position: relative;
        height: 110px;
    }

    .about-car-img img{
        width: 300px !important;
        margin: 0 auto;
        position: absolute;
        top: -110px;
    }

    .about-SP-flx{
        display: flex !important;
        flex-direction: column !important;
        margin-top: 40px !important;
        margin-bottom: 40px !important;
        row-gap: 14px !important;
    }

    .about-SP-flx .vm-container {
        height: fit-content;
        background-color: #000000;
        padding: 60px 30px 60px 30px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .about-SP-flx .vm-container p{
        text-align: left !important;
        margin-bottom: 0;
        padding-top: 18px;
    }

    .about-SP-flx .vm-border:hover{
        border-bottom: 14px solid #DAA676;
    }
    .founder-container {
        border-bottom: #DAA676 solid 150px;
        padding-left: 24px !important;
        padding-right: 24px !important;
        display: flex !important;
        flex-direction: column !important;

    }

    .founder-container .about-para{
        padding-top: 42px;
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 20px;
    }
    .founder-sig {
        color: #03008E;
        font-family: 'Photoshoot';
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 62px;
        letter-spacing: 1px;
        margin-top: 13%;
        text-align: left;
    }
    .founder-image {
        flex-shrink: 0;
        width: 265px;
        margin: 0 auto;
        margin-bottom: -150px;
        margin-left: 49%;
        
    }
    .awards-container{
        padding: 0px 24px ;
    }
    .awards-container .slick-slider .p-5{
        position: relative;
        width: 100% !important;
        padding: 0 !important;
    }
    .awards-container .slick-slider .p-5 .awart-img-container{
        position: absolute;
        z-index: 9;
        background-color: transparent !important;
        bottom: 50%;
    }
    .awards-container .slick-slider .p-5 .awart-img-container img{
        position: absolute;
        z-index: 9;
        background-color: transparent !important;
        width: 100% !important;
    }

    .awards-container .slick-slider .p-5 .col-8{
        height: 300px;
        object-fit: cover;
        width: 100% !important;
    }
    .awards-container{
        margin-bottom: 40px;
    }
    .award-gradient img{
        height: 300px !important;
        object-fit: cover;
        width: auto !important;
    }
    .awards-container .award{
        color: #FFF;
        font-family: 'Integral CF';
        font-size: 17px;
        font-style: normal;
        font-weight: 400;
        line-height: 30px; /* 176.471% */
        letter-spacing: 1.02px;
        top: 46%;
        left: 31%;
        max-width: 200px;
    }
    .meet-crew {
        background: url("../images/cwMob.webp") no-repeat center;
        background-size: cover;
        color: white;
        text-align: center;
        padding: 40% 0;
        margin: 40px 24px;
        margin-bottom: 40px;
        height: 70vh;
    }
    .meet-crew h1{
        color: #FFF;
        text-align: center;
        font-family: Integral CF;
        font-size: 27px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 1.62px;
    }

    .meet-crew p{
        padding-top: 24px !important;
        padding-bottom: 24px !important;
        width: 100% !important;
        text-align: center;
        margin-left: 0;
        line-height: 32px;
        padding: 0;
        font-size: 16px;
        font-weight: 300;
    }
    .meet-crew button{
        height: 50px;
    }
    .meet-crew .btn > .nav-link {
        color: #292929;
        text-align: center;
        font-family: Integral CF;
        font-size: 15.54px;
        font-style: normal;
        font-weight: 400;
        line-height: 15.54px; /* 100% */
    }

    .mob-on-sp{
        display: none !important;
    }
    .mobile{
        display: none;
    }

    .dis-mob-off{
        display: flex !important;
        flex-direction: column;
        row-gap:22px;
        margin-top: -80%;
    }

    .dis-mob-off .itm{
        display: flex;
        flex-direction: column;
        row-gap:0px;
    }
    .dis-mob-off .itm .num{
        color: #FFF;
        text-align: center;
        font-family: 'Space Grotesk';
        font-size: 40px;
        font-style: normal;
        font-weight: 700;
        line-height: 60px; /* 192.308% */
    }
    .dis-mob-off .itm .nme{
        color: #FFF;
        text-align: center;
        leading-trim: both;
        text-edge: cap;
        font-family: 'Space Grotesk';
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        line-height: auto; /* 250% */
    }
    .page-heading-main {
        text-align: center;
        font-family: 'Integral CF';
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 44px;
        letter-spacing: 1px;
    }
    .modal-promotion-header-para {
        font-family: 'Space Grotesk';
        font-size: 15px !important;
        font-style: normal;
        font-weight: 400;
        line-height: 25px;
        width: 95% !important;
        text-align: left;
    }
    .modal-promo-container .d-flex{
            display: flex!important;
            align-items: baseline;
            width: 100%;
            flex-direction: column;
    }
    .modal-close-btn{
        z-index: 10;

    }
    .modal-promotion-price {
        text-align: left;
        font-size: 28px;
        text-transform: capitalize;
        font-weight: 500;
    }
    .modal-tax{
        text-align: left;
    }
     #about-us-section-main-heading .sp-hdr-hdr-mn {
        font-size: 27px !important;
    }
    .sp-3-a{
        padding-right: 22px !important;
        padding-left: 22px !important;
    }
    .sp-3-b{
        padding-right: 22px !important;
        padding-left: 22px !important;
    }
}

@media only screen and (min-width: 1024px)
  {
    .mobile{
        display: none;
    }
}


.dis-mob-off{
    display: none;
}
#about-final-fs{
    line-height: 48px;
    padding-bottom: 19px;
    font-size: 68px !important;
    
}
#about-us-section-main-heading{
    font-size: 60px !important;
    letter-spacing: 2.88px;
    line-height: 24px;
    padding-bottom: 40px;
}

@media(min-width:1200px){
    .spc-2-abt{
        padding: 0 !important;
        padding-left: 5px !important;
        padding-right:11px !important;
    }
    .sp-3-a{
        padding: 0 !important;
    }
    .sp-3-b{
        padding-right: 11px !important;
    }
    .founder-container{
        padding-right: 16px !important;
    }
    .awards-container .p-5{
        padding: 0 !important;
        padding: 0 !important;
        padding-left: 0px !important;
        padding-right: 16px !important;
    }
    .meet-crew {
       
        margin-left: 2.5%;
        margin-right: 3.5%;
    }
    #about-us-section-main-heading {
        font-size: 64px !important;
        letter-spacing: 2.88px;
        line-height: 24px;
        padding-bottom: 40px;
        padding-top: 168px;
    }
    #about-us-section-main-heading.page-heading-main {
        font-size: 22px;
        letter-spacing: 2px;
        line-height: 72px;
        text-align: center;
    }
    #about-us-header-paragraph-intro {
        font-size: 20px;
        line-height: 24px;
        margin: -1px 0 0;
    }
    #about-us-detail-paragraph{
        font-size: 18px !important;
    }
    .mission-para{
        font-size: 18px !important;
    }
}