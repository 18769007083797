.partner-container {
    padding: 1% 0;
    scroll-snap-align: start;
    scroll-snap-stop: always;
}

.partner-container > .d-flex > .p-2 {
    text-align: center;
    padding: 0 !important;
}

.partner-container img {
    width: 40%;
}

.mobile-partner-container {
    display: none;
}

@media (max-width: 768px) {
    .partner-container {
        text-align: center;
    }

    .partner-container > .d-flex {
        display: block !important;
    }

    .partner-container > .d-flex > .p-2 {
        margin: 5% 0;
    }

    .desktop-partner-container {
        display: none;
    }

    .mobile-partner-container {
        display: block;
        padding: 40px 0px;
    }

    .mobile-partner-container .logo-pair-container{
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .mobile-partner-container .logo-pair-container .carousel-detail-container{
        width: 50%;
    }



    .mobile-partner-container .carousel .control-dots {
        display: none;
    }

    .mobile-partner-container .carousel .slider-wrapper {
        margin: 2% 0 !important;
    }

    .mobile-partner-container .carousel .slide img {
        width: 35%;
    }

    .mobile-partner-container .carousel-detail-container {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .partner-container img {
        width: 76% !important;
        justify-content: center;
        display: flex;
    }
}
@media (min-width:1200px){
    .partner-container img {
        width: auto;
        height: 62px;
        /* object-fit: cover; */
    }
}